import { Icon } from '@thryvlabs/maverick'

const ChevronLeftIcon = () => (
  <Icon
    type="solid"
    variant="chevronLeft"
    height="16.84"
    width="8.7"
    color={'#4D4D4D'}
  />
)

const BackButton = ({ className, children, isDisabled, ...rest }) => {
  return (
    <div className="inline-block">
      <button
        className={`${className} flex items-center transition-all duration-300 opacity-100 ${
          isDisabled && 'opacity-50 pointer-events-none'
        }`}
        {...rest}
      >
        <ChevronLeftIcon />

        <span className="ml-3 text-sm font-montserrat font-semibold">
          {children}
        </span>
      </button>
    </div>
  )
}

export default BackButton
