import { useState } from 'react'
import { RemoveContactDetail } from '../../command-center-icon/icons'

const Header = ({ children }) => (
  <p className="font-semibold text-sm text-[#231F20] font-montserrat mb-[4px] not-italic">
    {children}
  </p>
)

const Text = ({ children }) => (
  <p className="font-open-sans font-normal text-sm text-[#808080] not-italic truncate">
    {children}
  </p>
)

const EditButton = ({ onClick, ...rest }) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
      {...rest}
      type="button"
    >
      <RemoveContactDetail color={isHover ? '#057AFF' : '#A3A5A7'} />
    </button>
  )
}

const ContactDetailRow = ({
  className,
  title,
  children,
  editButton,
  isDisabled,
}) => {
  return (
    <div className={`${className} w-full flex justify-between items-center `}>
      <div className="max-w-[80%]">
        <Header>{title}</Header>
        <Text>{children}</Text>
      </div>

      <EditButton
        className={isDisabled && 'opacity-50 pointer-events-none'}
        {...editButton}
      />
    </div>
  )
}

export default ContactDetailRow
