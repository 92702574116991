import { useEffect, useState } from 'react'
// import AddContactPanel from './add-contact-panel/AddContactPanel' // Do we need this component?
import EditContactPanel from './edit-contact-panel/EditContactPanel'
import { useSelector } from 'react-redux'
import ContactDetailsPanel from './contact-details-panel/contact-details-panel'
import { FormContextProvider } from '../../right-panel/user-profile-and-activity/contexts/form-context'
import { useMediaQuery } from 'react-responsive'

export const ContactInfoView = ({ connection, setMobileContactSideSheetOpen }) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })

  // selectedPanel options: 'contact-details-panel', 'edit-contact-panel'
  const [selectedPanel, setSelectedPanel] = useState('contact-details-panel')
  const { selectedContact } = useSelector((state) => state.contacts)
  // const calls = useSelector((state) => state.calls.calls)

  // FIXME: COMMENTED OUT BECAUSE IT'S NOT USED - POTENTIALLY - REMOVE IF CONFIRMED NOT IN USE
  // const { createVariables, initMessageThreads, initPinnedMessageThreads } =
  //   useInboxMenuConversations()

  // FIXME: COMMENTED OUT BECAUSE IT'S NOT USED - POTENTIALLY - REMOVE IF CONFIRMED NOT IN USE
  // const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
  //   fetchPolicy: 'no-cache',
  //   variables: createVariables(),
  //   onCompleted: () => {
  //     const threads = threadsData?.queryThreads.items
  //     initMessageThreads(threads)
  //     const pinned = threadsData?.pinned?.items
  //     initPinnedMessageThreads(pinned)
  //   },
  // })

  // Closes any panel whenever a new contact is selected
  useEffect(() => {
    setSelectedPanel('contact-details-panel')
  }, [selectedContact])

  return (
    <div className="h-full p-[30px]">
      {/* FIXME: Do we need this component? */}
      {/* <AddContactPanel setContactInfoView={setContactInfoView} /> */}

      {/* NOTE: This div is used to keep a consistent height regardless if there is a back button or not. */}
      {selectedPanel === 'contact-details-panel' && !isMobile && (
        <div className="h-[21px] w-full"></div>
      )}

      <ContactDetailsPanel
        show={selectedPanel === 'contact-details-panel'}
        connection={connection}
        setMobileContactSideSheetOpen={setMobileContactSideSheetOpen}
        onEditButtonClick={() => setSelectedPanel('edit-contact-panel')}
      />

      {selectedPanel === 'edit-contact-panel' && (
        <FormContextProvider contact={selectedContact}>
          <EditContactPanel
            onBackButtonClick={() => setSelectedPanel('contact-details-panel')}
          />
        </FormContextProvider>
      )}
    </div>
  )
}

export default ContactInfoView
