import LeftNavBar from './components/left-navigation-bar/LeftNavBar'
import MobileNav from './components/mobile-navigation/MobileNav'
import TopNavBar from './components/top-navigation-bar/TopNavBar'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_USER_PREFERENCES } from './graphql/mutations/user-preferences-mutation'
import { GET_LABEL_OPTIONS_BY_LOCATION } from './graphql/queries'
import { GET_LEFT_NAV_INACTIVE_CENTERS } from './graphql/queries/left-nav-queries'
import { useSelector, useDispatch, batch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { setSelectedInboxMobilePage } from './components/inbox/slices/inboxSlice'
import {
  setCenterAccess,
  setUserPreferences,
} from './redux-toolkit/slices/user-preferences/user-preferences'
import { setTabSelected } from './components/calls/slices/callSlice'
import { useEffect, useState } from 'react'
import { setLabelOptions } from './components/inbox/slices/messageContentSlice'
import {
  filterUserPreferencesByCenter,
  calcHasReportingCenter,
} from './utils/left-nav-helpers'
import IncomingCallsWidget from './components/left-navigation-bar/incoming-calls-widget/IncomingCallsWidget'
import { callsSelector } from './components/calls/slices/calls.selectors'
import { userPreferencesSelector } from './redux-toolkit/slices/user-preferences/user-preferences.selectors'
import sidebarConfig from './sidebarConfig.js'
import axios from 'axios'
import { setAudioPermission } from './components/calls/slices/callSlice'
import { InboxSubscriptionListener } from './components/subscriptions/inbox-subscriptions.jsx'
import { CallsSubscriptionListener } from './components/subscriptions/calls-subscriptions.jsx'
import { DynamicPageTitle as Title } from '@title'
import { useAudioPermissionModal } from './components/calls/audioPermissionModal.jsx'
import { setSelectedThread } from './components/inbox/slices/inboxThreadsSlice'
import { AuthLoader } from '@authentication'
import { useFlags } from 'launchdarkly-react-client-sdk'

export function Layout({ children }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0()
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const { toggleReportingCenterThryvAI } = useFlags()
  const [activeRoute, setActiveRoute] = useState()
  const { audioPermission, onIncomingCall, onOutgoingCall, vonageClient } =
    useSelector(callsSelector)
  const {
    desktop: desktopNotifications,
    mobile: mobileNotifications,
    doNotDisturb,
  } = useSelector((state) => state.userPreferences.notificationPreferences)
  const incomingWeb = desktopNotifications?.find(
    (item) => item.label === 'Calls',
  )?.value

  const incomingMobile = mobileNotifications?.find(
    (item) => item.label === 'Calls',
  )?.value
  const [, , PermissionModal] = useAudioPermissionModal(isMobile, 'general')
  const {
    hasMarketingCenter,
    hasListingsManagement,
    hasBusinessCenter,
    hasSignatures,
    hasWebsiteBuilder,
    hasThryvLeads,
    hasReportingCenter,
  } = useSelector(userPreferencesSelector)
  const { navigationCollapsed, notificationPreferences, navigationPreferences } =
    sidebarConfig
  const [updateUserPreferences, { loading }] = useMutation(UPDATE_USER_PREFERENCES)
  const { VITE_NYLAS_HOST } = import.meta.env
  const isOnboarding = localStorage.getItem('onboarding')

  const navigate = useNavigate()

  // Apply overflow-scroll to all other pages
  const PAGES_WITHOUT_OVERFLOW_SCROLL = [
    '/calls',
    '/calls/voicemail',
    '/inbox',
    '/leads',
    '/inbox/add-channels',
    '/inbox/global-search/messages',
    '/inbox/global-search/contacts',
  ]
  const hasOverflowScroll = !PAGES_WITHOUT_OVERFLOW_SCROLL.includes(
    location.pathname,
  )

  // Request Microphone Permission
  const checkMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      dispatch(setAudioPermission(true))
      // Release the media stream to free up resources.
      stream.getTracks().forEach((track) => track.stop())
    } catch (error) {
      dispatch(setAudioPermission(false))
    }
  }

  useEffect(() => {
    if (audioPermission === 'loading') {
      checkMicrophoneAccess()
    }
  }, [audioPermission])

  async function delSession() {
    try {
      const url = `https://api.nexmo.com/v1/sessions/${vonageClient.session_id}`
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + vonageClient.jwt_sub,
      }
      const response = await fetch(url, {
        method: 'DELETE',
        headers: headers,
        keepalive: true,
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Fetch error:', error)
    }
  }

  const deregisterKnock = async () => {
    const androidToken = localStorage.getItem('FCM_TOKEN')
    const iosToken = localStorage.getItem('APNS_TOKEN')
    const mobileToken = androidToken || iosToken
    const accessToken = await getAccessTokenSilently()
    let deviceType = ''

    if (androidToken) {
      deviceType = 'android'
    }

    if (iosToken) {
      deviceType = 'ios'
    }

    if (mobileToken && accessToken) {
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      axios.post(
        `${VITE_NYLAS_HOST}/remove_knock_user`,
        {
          token: mobileToken,
          user_id: user.cc_uid,
          device: deviceType,
        },
        options,
      )
    }
  }

  useEffect(() => {
    const unloadEvent = (event) => {
      event.stopImmediatePropagation()
      delSession()
      deregisterKnock()
    }

    window.addEventListener('beforeunload', unloadEvent)

    return () => {
      window.removeEventListener('beforeunload', unloadEvent)
    }
  }, [deregisterKnock, delSession])

  const updatePreferences = async (preferences) => {
    try {
      const { data, order } = preferences.navigationPreferences
      let updatedNavigationPreferences = { ...preferences.navigationPreferences }
      if (data.length === order.length) {
        const dataMismatch = data.filter((item) => !order.includes(item.id))
        const orderMismatch = order.filter(
          (id) => !data.find((item) => item.id === id),
        )

        let replacements = []
        if (
          dataMismatch.length > 0 &&
          orderMismatch.length > 0 &&
          dataMismatch.length === orderMismatch.length
        ) {
          dataMismatch.forEach((item, i) => {
            replacements.push([item.id, orderMismatch[i]])
          })
        }

        let preferencesClone = JSON.parse(
          JSON.stringify(updatedNavigationPreferences),
        )

        for (const [centerId, newCenterId] of replacements) {
          const centerToUpdate = preferencesClone.data.find(
            (item) => item.id === centerId,
          )
          if (centerToUpdate) {
            centerToUpdate.id = newCenterId
          }
        }

        updatedNavigationPreferences = preferencesClone
      }
      if (data.length !== order.length) {
        const cleanedOrder = order.filter((id) =>
          data.find((item) => item.id === id),
        )

        const uniqueCleanedOrder = [...new Set(cleanedOrder)]

        updatedNavigationPreferences = {
          ...updatedNavigationPreferences,
          order: uniqueCleanedOrder,
        }
      }

      const updatedPreferences = {
        ...preferences,
        navigationPreferences: updatedNavigationPreferences,
      }

      dispatch(setUserPreferences(updatedPreferences || {}))

      await updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedPreferences,
          }),
        },
      })
    } catch (e) {
      //
    }
  }

  const { data: labelOptions, loading: labelsLoading } = useQuery(
    GET_LABEL_OPTIONS_BY_LOCATION,
    {
      variables: {
        locationid: user?.businessId || '',
      },
      onCompleted: () => {
        const updatedLabelOptions = labelOptions.queryLabelOptionsByLocation.map(
          (option) => {
            return { ...option, checked: false }
          },
        )
        dispatch(setLabelOptions(updatedLabelOptions))
      },
      onError: () => {},
    },
  )

  const { data: leftNavInactiveCenters } = useQuery(GET_LEFT_NAV_INACTIVE_CENTERS, {
    variables: {
      locationid: user?.businessId || '',
    },
    onCompleted: () => {
      dispatch(
        setCenterAccess({
          hasMarketingCenter:
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation
              ?.MarketingCenter === 1,
          hasListingsManagement:
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation?.Listings === 1,
          hasBusinessCenter:
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation?.BusinessCenter ===
            1,
          hasSignatures:
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation?.Signatures === 1,
          hasWebsiteBuilder:
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation?.WebsiteBuilder ===
            1,
          hasThryvLeads:
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation?.ThryvLeads !==
              null &&
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation?.ThryvLeads !==
              undefined,
          hasReportingCenter: calcHasReportingCenter(
            leftNavInactiveCenters?.queryLeftNavOptionsByLocation
              ?.ReportingCenter === 1,
            user,
          ),
        }),
      )
    },
    onError: () => {
      dispatch(
        setCenterAccess({
          hasMarketingCenter: false,
          hasListingsManagement: false,
          hasBusinessCenter: false,
          hasSignatures: false,
          hasWebsiteBuilder: false,
          hasThryvLeads: null,
          hasReportingCenter: calcHasReportingCenter(false, user),
        }),
      )
    },
  })

  const handlePushNotification = () => {
    const pushNotification = localStorage.getItem('OPENED_PUSH_NOTIFICATIONS')

    if (pushNotification !== 'undefined') {
      const parsedNotification = JSON.parse(pushNotification)

      const lastNotification = parsedNotification?.[parsedNotification.length - 1]

      const { type, threadId, subject } = lastNotification?.payload || {}

      try {
        switch (type) {
          case 'voicemail':
            dispatch(setTabSelected('voicemail'))
            navigate('/calls/voicemail')
            break
          case 'missed_call':
            dispatch(setTabSelected('recent'))
            navigate('/calls')
            break
          case 'thread':
            batch(() => {
              dispatch(
                setSelectedThread({
                  id: threadId,
                  name: subject,
                }),
              )
              dispatch(setSelectedInboxMobilePage('message-content'))
            })

            navigate('/inbox')
            break
          default:
            navigate('/inbox')
            break
        }
      } catch (error) {
        navigate('/inbox')
      }
    } else {
      navigate('/inbox')
    }
  }
  const IS_MOBILE = localStorage.getItem('IS_MOBILE')

  useEffect(() => {
    if (
      IS_MOBILE &&
      window.location.href.includes('/notification?notificationId=')
    ) {
      handlePushNotification()
    }
  }, [window.location.href])

  useEffect(() => {
    const callAddKnockEndpoint = async () => {
      const androidToken = localStorage.getItem('FCM_TOKEN')
      const iosToken = localStorage.getItem('APNS_TOKEN')
      const mobileToken = androidToken || iosToken
      const accessToken = await getAccessTokenSilently()
      let deviceType = ''

      if (androidToken) {
        deviceType = 'android'
      }

      if (iosToken) {
        deviceType = 'ios'
      }

      if (accessToken) {
        const options = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        if (mobileToken) {
          axios.post(
            `${VITE_NYLAS_HOST}/add_knock_user`,
            {
              token: mobileToken,
              user_id: user.cc_uid,
              device: deviceType,
            },
            options,
          )
        } else {
          axios.post(
            `${VITE_NYLAS_HOST}/add_desktop_knock_user`,
            {
              user_id: user.cc_uid,
            },
            options,
          )
        }
      }
    }

    if (!user?.cc_uid) return
    callAddKnockEndpoint()
  }, [user?.cc_uid])

  useEffect(() => {
    let activeLocation = location.pathname.split('/')
    setActiveRoute(
      activeLocation[1] &&
        activeLocation[1].replace(/^\w/, (match) => match.toUpperCase()),
    )
  }, [location])

  useEffect(() => {
    if (
      user &&
      hasBusinessCenter !== undefined &&
      hasMarketingCenter !== undefined &&
      hasListingsManagement !== undefined &&
      hasSignatures !== undefined &&
      hasWebsiteBuilder !== undefined &&
      hasThryvLeads !== undefined &&
      hasReportingCenter !== undefined
    ) {
      const authUserPreferences = user?.cc_metadata?.Values || null
      let mostRecentUserPreferences = null
      if (authUserPreferences) {
        mostRecentUserPreferences = JSON.parse(authUserPreferences)
      } else {
        const existingPreferences = structuredClone({
          navigationCollapsed,
          navigationPreferences,
          notificationPreferences,
        })
        mostRecentUserPreferences = {
          time_stamp: Date.now(),
          data: existingPreferences,
        }
      }

      const filteredUserPreferences = filterUserPreferencesByCenter(
        mostRecentUserPreferences,
        hasMarketingCenter,
        hasListingsManagement,
        hasBusinessCenter,
        hasSignatures,
        hasWebsiteBuilder,
        hasThryvLeads,
        {
          hasReportingCenter,
          toggleReportingCenterThryvAI,
          isPaid: user?.rc_settings?.isPaid || false,
        },
      )

      const desktop =
        filteredUserPreferences.data.notificationPreferences.desktop.filter(
          (x) =>
            x.label === 'Missed Calls' ||
            x.label === 'Incoming Calls' ||
            x.label === 'New Voicemail',
        )
      const mobile =
        filteredUserPreferences.data.notificationPreferences.mobile.filter(
          (x) =>
            x.label === 'Missed Calls' ||
            x.label === 'Incoming Calls' ||
            x.label === 'New Voicemail',
        )
      if (desktop.length > 0 && mobile.length > 0) {
        filteredUserPreferences.data.notificationPreferences.mobile = [
          {
            label: 'Inbox',
            value: true,
          },
          {
            label: 'TeamChat',
            value: true,
          },
          {
            label: 'Calls',
            value: true,
          },
        ]

        filteredUserPreferences.data.notificationPreferences.desktop = [
          {
            label: 'Inbox',
            value: true,
          },
          {
            label: 'TeamChat',
            value: true,
          },
          {
            label: 'Calls',
            value: true,
          },
        ]
      }

      updatePreferences(filteredUserPreferences?.data || {})
      setIsLoaded(true)
    }
  }, [
    user,
    hasBusinessCenter,
    hasMarketingCenter,
    hasListingsManagement,
    hasSignatures,
    hasWebsiteBuilder,
    hasThryvLeads,
    hasReportingCenter,
  ])
  const renderLayout =
    isOnboarding === 'true' && !window.location.pathname.includes('inbox') ? (
      <AuthLoader />
    ) : location.pathname !== '/signup' && !loading ? (
      <>
        {isMobile ? (
          <div id="cc-mobile-nav" className="min-w-md:hidden">
            <MobileNav />
          </div>
        ) : (
          <div id="cc-top-nav" className="md:hidden">
            <TopNavBar />
          </div>
        )}
        <div id="cc-left-nav" className="md:hidden">
          <LeftNavBar />
        </div>
      </>
    ) : (
      <div id="cc-left-nav" className="md:hidden">
        <LeftNavBar />
      </div>
    )
  return (
    isLoaded &&
    !labelsLoading && (
      <div id={location.pathname !== '/signup' ? 'command-center-container' : ''}>
        <Title pageTitle={`Command Center | ${activeRoute}`} />
        {renderLayout}
        <div
          id="cc-content"
          className={`h-full ${hasOverflowScroll && 'overflow-y-scroll'}`}
        >
          {/* Condition to render mobile incomingCall widget
              - Not in calls page: always render
              - in calls page: only render when not on outgoingCall
          */}
          {((['/calls', '/calls/voicemail'].includes(location.pathname) &&
            !onOutgoingCall) ||
            !['/calls', '/calls/voicemail'].includes(location.pathname)) &&
            isMobile &&
            vonageClient.isInit && <IncomingCallsWidget isMobile={true} />}
          {/* Condition to render mobile children
              - Not on incoming call
              - when not in calls page, not on outgoing call
              - dnd is on
          */}
          {isMobile &&
          !(
            doNotDisturb ||
            (!isMobile && !incomingWeb) ||
            (isMobile && !incomingMobile)
          ) &&
          vonageClient.isInit &&
          (onIncomingCall ||
            (onOutgoingCall &&
              !['/calls', '/calls/voicemail'].includes(location.pathname)))
            ? null
            : children}
        </div>
        {location.pathname.includes('/inbox') && <InboxSubscriptionListener />}
        {location.pathname.includes('/calls') && <CallsSubscriptionListener />}
        {audioPermission === false && PermissionModal}
      </div>
    )
  )
}
