import useAvatarComponent from '../../../../../hooks/useAvatarComponent'
import { useFormContext } from '../../../../right-panel/user-profile-and-activity/contexts/form-context'
import { useSelector } from 'react-redux'
import { trimPhoneCountryCode } from '../utils/trim-phone-country-code'

// Components
import { LoadingDots } from '../../../../common/loading-dots'
import { SaveButton } from '../components/save-button/SaveButton'
import { CommandCenterNotification } from '../../../../common/command-center-notification/command-center-notification'
import {
  FirstNameInput,
  LastNameInput,
  PhoneInput,
  EmailInput,
} from '../components/inputs/Inputs'
import BackButton from '../../components/back-button'
import { capitalizeWords } from '../../../../../utils/capitalize-words'

const EditContactFormCalls = ({
  className,
  submissionIsLoading,
  onSubmit = () => {},
  showError,
  hideErrorMessage,
  errorMessage,
  contact = {
    username: 'Unknown',
    pictureUrl: '#',
    phone: 1234567890,
  },
  onBackButtonClick,
}) => {
  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const { errors, isValid, isDirty, control, handleSubmit } = useFormContext()

  const { RenderUserProfile } = useAvatarComponent({
    avatarSize: 'default',
    picture_url: contact.pictureUrl,
    contactName: capitalizeWords(contact.username),
  })

  return (
    <div className={`${className}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between h-[21px] items-center">
          <BackButton
            onClick={onBackButtonClick}
            data-testid="contact-card-back-btn"
            aria-label="Back to contact details"
            isDisabled={submissionIsLoading || isDirty}
            type="button"
          >
            Back
          </BackButton>

          {!submissionIsLoading && <SaveButton enabled={isValid && isDirty} />}
          {submissionIsLoading && <LoadingDots />}
        </div>

        <div className="relative mb-[16px]">
          <div className="opacity-50 pointer-events-none">
            {/* THIS IS THE AVATAR - IT IS DISABLED FOR NOW. */}
            <RenderUserProfile />
          </div>
        </div>

        <ul className="flex flex-col">
          <li>
            <FirstNameInput
              error={errors.firstName?.message}
              control={control}
              disabled={submissionIsLoading}
            />
          </li>

          <li>
            <LastNameInput
              error={errors.lastName?.message}
              control={control}
              disabled={submissionIsLoading}
            />
          </li>

          <li>
            <PhoneInput
              error={errors.phone?.message}
              control={control}
              phoneNumber={trimPhoneCountryCode(contact.phone, countryCode)}
              disabled={contact.phone === 'error' || submissionIsLoading}
            />
          </li>

          <li>
            <EmailInput
              error={errors.email?.message}
              control={control}
              disabled={submissionIsLoading}
            />
          </li>
        </ul>
      </form>

      <CommandCenterNotification
        variant="warning"
        show={showError}
        onCloseClick={hideErrorMessage}
        disableCloseButton={true}
      >
        {errorMessage}
      </CommandCenterNotification>
    </div>
  )
}

export default EditContactFormCalls
