import useAvatarComponent from '../../../../../hooks/useAvatarComponent'
import formatContactData from '../../../../inbox/hooks/formatContactData'

// Components
import { CommandCenterNotification } from '../../../../common/command-center-notification/command-center-notification'
import { Button } from '@thryvlabs/maverick'
import { useSelector } from 'react-redux'

const ContactUpdatePreview = ({ children, title, className }) => {
  return (
    <div className={`${className} flex gap-[16px]`}>
      <p className="text-[14px] font-normal font-open-sans text-[#808080] w-[125px]">
        {title}
      </p>
      <p className="text-[14px] font-normal font-open-sans text-[#231F20] underline truncate">
        {children}
      </p>
    </div>
  )
}

// AVAILABLE VARIANTS: email, phone, both
const ContactUpdateConfirmationPanel = ({
  variant,
  isMobile,
  onCancelClick,
  onConfirmClick,
}) => {
  const { selectedContact: contact } = useSelector((state) => state.contacts)
  const { email, phone, username } = contact
  const formattedPhone = formatContactData(phone) || ''

  const { RenderUserProfile } = useAvatarComponent({
    avatarSize: 'default',
    picture_url: contact.pictureUrl,
    contactName: contact.username,
  })

  let notificationText
  let showEmail = false
  let showPhone = false

  switch (variant) {
    case 'email':
      notificationText = `Removing this email will result in the loss of all messages associated with
        ${email}. Please confirm you want to overwrite this email.`
      showEmail = true
      break

    case 'phone':
      notificationText = `Removing this number will result in the loss of all SMS messages associated with ${formattedPhone}. Please confirm you want to overwrite this number.`
      showPhone = true
      break

    case 'both':
      notificationText = `Removing this email will result in the loss of all messages associated with ${email}, and removing this number will result in the loss of all SMS messages associated with ${formattedPhone}. Please confirm you want to overwrite these details.`
      showEmail = true
      showPhone = true
      break
  }

  return (
    <div>
      <CommandCenterNotification
        className="mb-[40px]"
        variant="warning"
        show={true}
        setShow={() => {}}
        disableCloseButton={true}
      >
        {notificationText}
      </CommandCenterNotification>

      <div className="flex gap-[12px] items-center mb-[16px]">
        {/* THIS IS THE AVATAR - IT IS DISABLED FOR NOW. */}
        <div className="pointer-events-none">
          <RenderUserProfile size="small" />
        </div>

        <p className="text-[16px] font-normal font-open-sans text-[#231F20]">
          {username}
        </p>
      </div>

      <div className="flex flex-col gap-[10px] mb-[70px]">
        {showEmail && (
          <ContactUpdatePreview title="Email to update:">
            {email}
          </ContactUpdatePreview>
        )}

        {showPhone && (
          <ContactUpdatePreview title="Number to update:">
            {formattedPhone}
          </ContactUpdatePreview>
        )}
      </div>

      <div className={`flex ${isMobile && 'flex-wrap'} gap-[10px] justify-center`}>
        <Button
          variant="primary"
          className="w-[186px] h-[40px]"
          onClick={onConfirmClick}
        >
          Confirm
        </Button>
        <Button
          variant="primary"
          onClick={onCancelClick}
          className="w-[186px] h-[40px] !bg-[#EDEDF2] !text-[#231F20] !border-[#EDEDF2] hover:!bg-[#D4D4D8] hover:!border-[#D4D4D8]"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default ContactUpdateConfirmationPanel
