// THIS FILE MAY BE REDUNDANT, BUT IT'S HERE FOR REFERENCE
// IF ALL FILES THAT USE THIS CAN USE THE react-highlight-words library
// THEN THIS FILE IS GOOD TO DELETE
// REFERENCE: https://www.npmjs.com/package/react-highlight-words

/*
#########################
##     INFORMATION     ##
#########################
Returns a string with html that bolds first or all occurences of substr word
This bolds text by default, but you can style how you like by passing a tailwind
class in third argument

Example result:
Original String: "I am Bold."
New String: "I am <span class="font-bold">Bold</span>."

To use in react, you must use dangerouslySetInnerHTML for this to work. The following
article covers safe use cases:
https://blog.logrocket.com/using-dangerouslysetinnerhtml-in-a-react-application/

And the official documentation about it:
https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
*/

const styleSearchTermInString = {
  firstOccurenceOnly: (str, substr, className) => {
    /* Reference */
    /* https://stackoverflow.com/questions/29896907/bold-part-of-string */

    const n = str.toUpperCase()
    const q = substr.toUpperCase()
    const x = n.indexOf(q)
    if (!q || x === -1) {
      return str // bail early
    }
    const l = q.length

    return (
      str.substr(0, x) +
      `<span class="${className ? className : 'font-bold'}">` +
      str.substr(x, l) +
      '</span>' +
      str.substr(x + l)
    )
  },

  allOccurences: (str, substr, className) => {
    /* Reference */
    /* https://stackoverflow.com/questions/1230445/javascript-regex-how-to-bold-specific-words-with-regex */
    if (!str || !str.length) return ''

    if (!substr || !substr.length) return str

    let regExpParams
    try {
      regExpParams = new RegExp(`(^|)(${substr})(|$)`, 'ig')
    } catch (err) {
      return ''
    }

    return str.replace(
      regExpParams,
      `$1<span class="${className ? className : 'font-bold'}">$2</span>$3`,
    )
  },
}

export default styleSearchTermInString
