import { useDispatch, useSelector } from 'react-redux'
import RecentContentMobile from './RecentContentMobile'
import RecentContent from './RecentContent'
import { updateSelectedContact } from '@redux-toolkit'
import { setPrepopulatedNumber } from '../../../slices/callSlice'

const Recent = ({
  body,
  agent_id,
  item_type,
  number_from,
  sk1,
  pk1,
  number_to,
  setDisplayInfoCard,
  isMobile,
  callsReceivedTab,
  threadpk,
  ts,
  setContactInfoView,
  setShowMobileContact,
  contact,
}) => {
  const dispatch = useDispatch()
  const { phoneNumber: provisionedNumber } = useSelector(
    (state) => state.contacts.contacts,
  )

  const call = {
    agent_id,
    body,
    item_type,
    number_from,
    number_to,
    pk1,
    sk1,
    threadpk,
    ts,
  }

  function handleClick() {
    let phone = ''
    // Use the callee's number if the call is from the provisioned number, otherwise use the caller's.
    if (call?.number_from === provisionedNumber) {
      phone = number_to
    } else {
      phone = number_from
    }

    dispatch(
      updateSelectedContact({
        unformattedPhone: phone,
        sk1: sk1,
        defaultValues: {
          email: contact.contactsk2,
          threadpk: contact.contactsk1,
        },
      }),
    )

    dispatch(setPrepopulatedNumber(phone))
    setContactInfoView('default')
  }

  const isNum = /\d{10}/.test(contact?.contactsk3)

  return (
    <>
      {isNum || contact?.contactsk3 === 'Anonymous' ? (
        <>
          <div
            onClick={handleClick}
            className={`flex flex-col active:bg-thryv-cloud hover:bg-[#F2F4F6] hover:cursor-pointer group justify-center h-[74px] w-full rounded-[5px] font-open-sans mb-[5px]`}
          >
            <div className="flex md:px-[20px] pl-[20px] items-center flex-wrap">
              {isMobile ? (
                <RecentContentMobile
                  call={call}
                  hasVoicemail={false}
                  setDisplayInfoCard={setDisplayInfoCard}
                  callsReceivedTab={callsReceivedTab}
                  setShowMobileContact={setShowMobileContact}
                  contact={contact}
                />
              ) : (
                <RecentContent call={call} hasVoicemail={false} contact={contact} />
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Recent
