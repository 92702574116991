export const LoadingSpinner = ({ className, widthAndHeight, absolute }) => (
  <span
    data-testid="loading-spinner"
    className={`${className} border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative left-1/2 top-1/2`}
    style={{
      width: widthAndHeight ? widthAndHeight : '48px',
      height: widthAndHeight ? widthAndHeight : '48px',
      position: absolute ? 'absolute' : 'inherit',
    }}
  ></span>
)
