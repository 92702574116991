import { Avatar } from '@thryvlabs/maverick'
import { useRedux } from '@hooks'
import { selectContactsData, updateSelectedContact } from '@redux-toolkit'
import avatarColorPicker from '../../../utils/avatarColorPicker'
import { ThryvGuyIcon } from '../../command-center-icon/icons'
import formatContactData from '../../inbox/hooks/formatContactData'
import { setPrepopulatedNumber } from '../slices/callSlice'
const contactContainerStyles =
  'w-full h-[60px] flex gap-3 py-2 px-4 items-center justify-start mb-2'
const contactInfoFlexContainerStyles = 'flex justify-between items-center'
const contactInfoFlexColContainerStyles = 'flex flex-col items-start justify-start '

const Contact = ({ setMobileContactSideSheetOpen, contact, setContactInfoView }) => {
  const [dispatch, useSelector] = useRedux()
  const {
    contacts: { contactsList },
  } = useSelector(selectContactsData)
  const { phone, username, firstName, lastName = '', sk1 } = contact

  const renderContactOnLoading = !contactsList.length ? (
    <div className={`${contactContainerStyles}`}>
      <div className="animate-pulse w-[45px] h-[45px] rounded-full bg-thryv-gray-medium-200" />
      <div className={`${contactInfoFlexContainerStyles}`}>
        <div className={`${contactInfoFlexColContainerStyles} gap-[4px]`}>
          <div className="animate-pulse w-[200px] h-4 rounded-[4px] bg-thryv-gray-medium-200" />
          <div className="animate-pulse w-[150px] h-4 rounded-[4px] bg-thryv-gray-medium-200" />
        </div>
      </div>
    </div>
  ) : null

  const handleClick = () => {
    dispatch(
      updateSelectedContact({
        unformattedPhone: phone,
        sk1: sk1,
      }),
    )

    setMobileContactSideSheetOpen(true)
    setContactInfoView('default')
    dispatch(setPrepopulatedNumber(phone))
    // dispatch(setPrepopulatedNumberClicked(!prepopulatedNumberClicked))
  }

  const renderContact = contactsList.length ? (
    <div>
      <div
        className="w-full h-[60px] flex gap-3 py-2 px-4 md:pl-[20px] items-center justify-start min-w-md:mb-2 cursor-pointer hover:bg-[#ECEEF1] transition duration-200 ease-out"
        onClick={handleClick}
      >
        {username === 'Thryv Support Center' ? (
          <ThryvGuyIcon className={``} />
        ) : (
          <Avatar
            variant="name"
            size="default"
            name={{
              firstName: firstName ? firstName : lastName ? ' ' : '#',
              lastName: lastName ? lastName : null,
            }}
            staticBackground
            backgroundColor={avatarColorPicker(lastName[0])}
            className="place-items-start"
          />
        )}
        <div className={`w-[255px] ${contactInfoFlexContainerStyles}`}>
          <div className={`${contactInfoFlexColContainerStyles} gap-[2px]`}>
            <span className="font-open-sans text-base md:pl-[5px] font-normal">
              {username}
            </span>
            <span className="md:hidden font-open-sans text-sm font-normal text-[#808080]">
              {formatContactData(phone)}
            </span>
          </div>
          {/* <div className="w-[24px] place-items-end md:pl-[5px] cursor-pointer">
            <InformationIcon height="36px" width="36px" fill="#CCCCCC" />
          </div> */}
        </div>
      </div>
      <hr className="text-[#ECEEF1] ml-[16px] min-w-md:hidden w-11/12" />
    </div>
  ) : null
  return (
    <>
      {renderContactOnLoading}
      {renderContact}
    </>
  )
}

export default Contact
