import { InformationIcon } from '../../../../command-center-icon/icons'
import CallAvatar from '../../recent-and-voicemail-components/CallAvatar'
import moment from 'moment'
import formatContactData from '../../../../inbox/hooks/formatContactData'

const VoicemailContentMobile = ({
  voicemail,
  setDisplayInfoCard,
  contact = {
    firstName: '',
    lastName: '',
    fullName: '',
    phone: '',
  },
}) => {
  const getVoicemailDate = (voicemail) => {
    return moment(voicemail.ts).local().format('ll')
  }

  const formattedPhoneNumber = contact
    ? contact.phone === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(contact.phone)
    : null

  const title =
    contact.fullName.length > 1 && !/^\d/.test(contact.firstName)
      ? contact.fullName
      : formattedPhoneNumber

  return (
    <>
      <div
        className="flex pr-[10px] pl-[13px]"
        data-testid="voicemail-mobile-container"
      >
        <CallAvatar
          call={voicemail}
          firstName={contact.firstName}
          lastName={contact.lastName}
        />
      </div>
      <div className={`flex flex-col grow justify-center`}>
        <div
          className={`${
            !voicemail.voiceMailPlayed ? '' : ''
          } block max-w-[175px] truncate font-semibold`}
        >
          {title}
        </div>
        <div className="flex flex-row text-thryv-black pt-[5px]">
          <div className="flex text-[14px]">{voicemail.location}</div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex flex-row text-thryv-steel text-[14px] pr-[8px] items-center">
          <div className="flex flex-col pr-[16px]">
            <span className="flex pb-[2px]">{getVoicemailDate(voicemail)}</span>
            {/* Commenting out voicemail length for now */}
            {/* <span className="flex pt-[2px] justify-end">00:50</span> */}
          </div>
          <div
            onClick={() => {
              setDisplayInfoCard(true)
            }}
            className="flex pr-[6px] items-center"
            data-testid="voicemail-info-button"
          >
            <InformationIcon height="36px" width="36px" fill="#CCCCCC" />
          </div>
        </div>
      </div>
    </>
  )
}

export default VoicemailContentMobile
