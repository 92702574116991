import { useSelector, useDispatch } from 'react-redux'
import { setPrepopulatedNumberClicked } from '../../slices/callSlice'
import { setOpenVideoCallLinkModal } from '../../slices/callSlice'
import formatContactData from '../../../inbox/hooks/formatContactData'
import ContactDetailRow from '../../../right-panel/components/contact-detail-row'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

// Components
import { UserAvatar } from '../../../right-panel/mobile/components/UserAvatar'
import { SelectedContactEmptyState } from '@icons'
import RecentCalls from '../recentcall-section/RecentCalls'
import { Button } from '@thryvlabs/maverick'
import BackButton from '../components/back-button'
import ViewInBusinessCenterLink from '../../../right-panel/user-profile-and-activity/contact-panel/components/ViewInBusinessCenterLink'
import { useVideoAndPhoneButtonData } from '../../../right-panel/user-profile-and-activity/hooks/useVideoAndPhoneButtonData'
import { FaPhoneAlt, FaVideo } from 'react-icons/fa'
import { useVideoRoomData } from '../../../right-panel/user-profile-and-activity/hooks/useVideoRoomData'
import { TooltipWithCenteredArrow } from '../../../common/tooltip-with-centered-arrow'

const { VITE_ENV } = import.meta.env

// ***** CONSTANTS *****
const BUSINESS_CENTER_BASE_URL =
  VITE_ENV === 'prod' ? 'https://go.thryv.com' : 'https://emp.thryv.com'

const CirclePhoneIcon = () => {
  return (
    <div className="rounded-full h-[38px] w-[38px] flex justify-center items-center bg-[#F3F5F9] group-hover:bg-[#057AFF] ">
      <FaPhoneAlt size={17} />
    </div>
  )
}

export const PhoneButton = ({ className = '', disabled, ...rest }) => {
  return (
    <button
      className={`
        ${className}
        ${disabled && 'opacity-30 pointer-events-none'}
        transition-all duration-300 group
        hover:text-[white]
      `}
      {...rest}
    >
      <CirclePhoneIcon />

      <p className='font-["Open_Sans"] font-normal text-xs mt-1 text-[#231F20]'>
        Call
      </p>
    </button>
  )
}

const CircleVideoIcon = ({ isActive }) => {
  return (
    <div
      className={`
        ${isActive && '!bg-[#16A085]'} 
        rounded-full h-[38px] w-[38px] flex justify-center items-center bg-[#F3F5F9] group-hover:bg-[#057AFF]
      `}
    >
      <FaVideo size={17} />
    </div>
  )
}

export const VideoButton = ({
  className = '',
  disabled,
  tooltipVisible,
  isActive,
  ...rest
}) => {
  return (
    <TooltipWithCenteredArrow
      variant="bottom"
      title="Click the video icon to join the on-going call."
      disableVisibleOnClick={true}
      visible={tooltipVisible}
      width="175px"
    >
      <button
        className={`
          ${className}
          ${disabled && 'opacity-30 pointer-events-none'}
          ${isActive && 'text-[#ffffff]'}
          transition-all duration-300 group
          hover:text-[white]
        `}
        {...rest}
      >
        <CircleVideoIcon isActive={isActive} />

        <p className='font-["Open_Sans"] font-normal text-xs mt-1 text-[#231F20]'>
          Video
        </p>
      </button>
    </TooltipWithCenteredArrow>
  )
}

const ContactDetails = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { prepopulatedNumberClicked } = useSelector((state) => state.calls)
  const { selectedContact } = useSelector((state) => state.contacts)

  const { hasBusinessCenter } = useSelector((state) => state.userPreferences)
  const hasContactInVcita = selectedContact.body?.vclientid
  const showBusinessCenterButton = hasBusinessCenter && hasContactInVcita

  const { isVideoCallActive } = useVideoRoomData()

  const {
    videoButton, // This is a object with the following keys: isVisible, isDisabled
    phoneButton, // This is a object with the following keys: isVisible, isDisabled
  } = useVideoAndPhoneButtonData(selectedContact)

  return (
    <div
      className="w-full flex flex-col gap-4 items-center justify-start"
      data-testid="contact-details"
    >
      <div className="relative mb-[8px]">
        <UserAvatar
          isThryvGuy={selectedContact.email === 'support@thryv.com'}
          imageUrl={selectedContact?.pictureUrl}
          userName={selectedContact.username}
        />
      </div>

      <p className="font-montserrat font-semibold text-base truncate max-w-[100%]">
        {selectedContact.username}
      </p>

      <div className="w-full flex gap-8 items-center justify-center">
        <PhoneButton
          onClick={() =>
            dispatch(setPrepopulatedNumberClicked(!prepopulatedNumberClicked))
          }
          disabled={phoneButton.isDisabled}
          aria-label="Call selected contact"
        />

        <VideoButton
          onClick={() => {
            if (isVideoCallActive) navigate('/meetings')
            else dispatch(setOpenVideoCallLinkModal(true))
          }}
          disabled={videoButton.isDisabled}
          aria-label="Initiate Video Call"
          tooltipVisible={isVideoCallActive}
          isActive={isVideoCallActive}
        />
      </div>

      {showBusinessCenterButton && (
        <ViewInBusinessCenterLink
          href={`${BUSINESS_CENTER_BASE_URL}/app/clients/${selectedContact.body.vclientid}`}
        />
      )}
    </div>
  )
}

const ContactInfo = ({ onEditButtonClick }) => {
  const { selectedContact } = useSelector((state) => state.contacts)

  const { phone, email } = selectedContact

  const phoneNumber = formatContactData(phone) || ''

  return (
    <address className="text-left flex flex-col gap-[16px]">
      <ContactDetailRow
        title="Mobile"
        editButton={{
          onClick: () => onEditButtonClick('mobile'),
          'data-testid': 'edit-phone-btn',
          'aria-label': 'Edit Phone Number',
        }}
        isDisabled={selectedContact.email === 'support@thryv.com'}
      >
        {phoneNumber.length ? phoneNumber : '--'}
      </ContactDetailRow>

      <ContactDetailRow
        title="Email"
        editButton={{
          onClick: () => onEditButtonClick('email'),
          'data-testid': 'edit-email-btn',
          'aria-label': 'Edit Email Number',
        }}
        isDisabled={selectedContact.email === 'support@thryv.com'}
      >
        {email.length ? email : '--'}
      </ContactDetailRow>
    </address>
  )
}

const ContactDetailsPanelEmptyState = ({ show }) => {
  return show ? (
    <div className="w-full flex flex-col items-center justify-center gap-6 mt-[63px]">
      <SelectedContactEmptyState />
      <div className="w-[267px] flex flex-col gap-5 items-center justify-center">
        <span className='w-full "font-open-sans text-sm text-[#808080] font-normal text-center'>
          Your contact cards will appear here after you get a phone number or connect
          Inbox channels.
        </span>
        {/* <Button variant="secondary">CONNECT CHANNELS</Button> */}
      </div>
    </div>
  ) : null
}

const ContactDetailsPanel = ({
  show,
  connection,
  setMobileContactSideSheetOpen,
  onEditButtonClick,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const { selectedContact } = useSelector((state) => state.contacts)

  const { email, phone, pk1 = '', username, threadpk } = selectedContact

  const showCreateContactButton =
    pk1.includes('not-a-real-pk1') && email !== 'support@thryv.com'

  return show ? (
    <div className="w-full h-full bg-white md:border-none">
      <ContactDetailsPanelEmptyState show={!connection} />

      <div className="w-full flex flex-col">
        {isMobile && (
          <BackButton
            type="button"
            onClick={() => {
              setMobileContactSideSheetOpen(false)
            }}
          >
            Back
          </BackButton>
        )}

        <ContactDetails />

        <hr className="text-[#E3E6E8] my-[24px]" />

        <ContactInfo onEditButtonClick={onEditButtonClick} />

        <hr className="text-[#E3E6E8] my-[24px]" />

        <RecentCalls
          className="mb-3"
          show={connection && phone}
          threadpk={threadpk}
          username={username}
        />

        {showCreateContactButton && (
          <Button
            variant="secondary"
            className="my-4 w-[178px] h-7 text-[11px] leading-[14px] break-normal m-auto"
            onClick={onEditButtonClick}
          >
            CREATE NEW CONTACT
          </Button>
        )}
      </div>
    </div>
  ) : null
}

export default ContactDetailsPanel
