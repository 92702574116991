import { useEffect, useState } from 'react'
import VoicemailContent from './VoicemailContent'
import VoicemailContentMobile from './VoicemailContentMobile'
import { updateSelectedContact } from '../../../../../redux-toolkit/slices/calls/contacts/contacts-slice'
import { useDispatch, useSelector } from 'react-redux'
import useCheckVoicemailExistence from '../../../../inbox/hooks/useCheckVoicemailExistence'
import { setPrepopulatedNumber } from '../../../slices/callSlice'
import { useLazyQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'

const shapeContactForFE = (queriedContact) => {
  const contact = {}
  const parsedBody = JSON.parse(queriedContact.body)
  contact.body = parsedBody
  contact.firstName = parsedBody.given_name
  contact.lastName = parsedBody.surname
  contact.phone = queriedContact.contactsk3
  contact.fullName = `${contact.firstName} ${contact.lastName}`
  return contact
}

const Voicemail = ({
  isMobile,
  setDisplayInfoCard,
  body,
  agent_id,
  item_type,
  number_from,
  sk1,
  pk1,
  number_to,
  threadpk,
  ts,
  setContactInfoView,
  msgpk,
  chanpk,
}) => {
  const dispatch = useDispatch()
  const { selectedContact } = useSelector((state) => state.contacts)

  const [contact, setContact] = useState({
    body: {},
    firstName: '',
    lastName: '',
    fullName: '',
    phone: '', // aka contactsk3 when querying from BE
  })

  const [voiceMailOpen, setVoicemailOpen] = useState(false)

  const voicemail = {
    body,
    agent_id,
    item_type,
    number_from,
    sk1,
    pk1,
    number_to,
    threadpk,
    ts,
    msgpk,
    chanpk,
  }

  const { isVoicemailExist, audioBlob, transcript, isLoadingAudio } =
    useCheckVoicemailExistence(voicemail)

  const [getContact] = useLazyQuery(FETCH_CONTACT_BY_THREAD, {
    fetchPolicy: 'network-only',
    variables: { threadid: voicemail?.threadpk?.split('#')?.at(0) },
  })

  const initContact = async () => {
    const contactData = await getContact()
    const queriedContact = contactData?.data.queryContactsByThread?.items?.at(0)
    const newContact = shapeContactForFE(queriedContact)
    setContact(newContact)
  }

  useEffect(() => {
    initContact()
  }, [selectedContact])

  function onVoicemailRowClick() {
    const newContact = {
      unformattedPhone: voicemail?.number_from,
      sk1: sk1,
      defaultValues: {
        threadpk: threadpk,
      },
    }

    dispatch(updateSelectedContact(newContact))
    dispatch(setPrepopulatedNumber(voicemail?.number_from))

    setContactInfoView('default')
  }

  useEffect(() => {
    setVoicemailOpen(false)
  }, [isMobile])

  return (
    <table className="w-full">
      <tbody className="w-full">
        <tr
          onClick={onVoicemailRowClick}
          className={`flex flex-col active:bg-thryv-cloud hover:bg-[#F2F4F6] hover:cursor-pointer group md:px-[5px] justify-center h-[74px] w-full rounded-[5px] font-open-sans mb-[5px]`}
        >
          <td className={'flex items-center flex-wrap'}>
            {isMobile ? (
              <VoicemailContentMobile
                voicemail={voicemail}
                voiceMailOpen={voiceMailOpen}
                setDisplayInfoCard={setDisplayInfoCard}
                contact={contact}
              />
            ) : (
              <VoicemailContent
                contact={contact}
                voicemail={voicemail}
                voiceMailOpen={voiceMailOpen}
                setVoicemailOpen={setVoicemailOpen}
                isVoicemailExist={isVoicemailExist}
                audioBlob={audioBlob}
                isLoadingAudio={isLoadingAudio}
              />
            )}
          </td>
        </tr>
        <tr
          className={`${
            voiceMailOpen ? '' : 'collapse'
          } text-thryv-night bg-[#f8f9fb]`}
        >
          <td className="flex flex-row p-[15px] rounded-[5px]">
            <p className="flex w-full px-[10px]">{transcript}</p>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default Voicemail
