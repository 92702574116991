import './thryvpay.css'

const ScheduledPayments = () => {
  const { VITE_THRYV_PAY_URL } = import.meta.env

  return (
    <div className="iframe-parent">
      <iframe
        width="100%"
        height="100%"
        src={`${VITE_THRYV_PAY_URL}/payments-scheduled?command_center`}
      />
    </div>
  )
}

export default ScheduledPayments
