import { Avatar, ParagraphText } from '@thryvlabs/maverick'
import DOMPurify from 'dompurify'
import { MessageSocialIcon } from '../../../common/message-social-icon/message-social-icon'
import styleSearchTermInString from '../../../../../../utils/styleSearchTermInString'
import getFirstAndLastName from '../../../../utils/getFirstAndLastName'

const MessageAvatar = ({ className, fullname, imageUrl }) => {
  const { firstName, lastName } = getFirstAndLastName(fullname)

  if (imageUrl) {
    return (
      <Avatar
        className={`${className} !w-8 !h-8`}
        imageUrl={imageUrl}
        variant="image"
        size="small"
      />
    )
  } else {
    return (
      <Avatar
        className={`${className} !w-8 !h-8`}
        variant="name"
        size="small"
        name={{
          firstName: firstName,
          lastName: lastName,
        }}
        staticBackground
        backgroundColor="#FF5000"
      />
    )
  }
}

export const MessageRow = ({
  user,
  title,
  timestamp,
  text,
  searchTerm,
  onClick,
}) => {
  const markupWithBoldWord = (str) => {
    return {
      __html: DOMPurify.sanitize(
        styleSearchTermInString.allOccurences(str, searchTerm),
      ),
    }
  }

  return (
    <div
      className="p-4 bg-[#FFFFFF] border border-[#ECEEF1] rounded cursor-pointer"
      onClick={onClick.container}
    >
      <div className="flex items-center mb-2.5">
        <a className="mr-2.5" href="#placeholder">
          <MessageAvatar fullname={user.name} imageUrl={user.imageUrl} />
        </a>

        <button type="button" onClick={onClick.name}>
          <ParagraphText variant="reg" className="mr-2 hover:text-[#057AFF]">
            <span dangerouslySetInnerHTML={markupWithBoldWord(user.name)} />
          </ParagraphText>
        </button>

        <MessageSocialIcon variant={user.social} />
      </div>

      <div className="mb-2">
        {title ? (
          <ParagraphText
            variant="reg"
            color="[#4D4D4D]"
            className="font-semibold mb-2"
          >
            {title}
          </ParagraphText>
        ) : null}
        <ParagraphText variant="reg" color="[#4D4D4D]">
          <span dangerouslySetInnerHTML={markupWithBoldWord(text)} />
        </ParagraphText>
      </div>

      {timestamp && timestamp !== 'Invalid date' ? (
        <div>
          <ParagraphText variant="sm" color="[#A3A5A7]">
            {timestamp}
          </ParagraphText>
        </div>
      ) : null}
    </div>
  )
}
