import { LoadingSpinner } from '../common/loading-spinner'
import { useNavigate } from 'react-router'
import { Button } from '@thryvlabs/maverick'
import {
  handleConnectChannel,
  setModalContent,
  setShowModal,
} from '../common/modals/slices/connectChannelsSlice'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { batch } from 'react-redux'
import { useState, useEffect } from 'react'
import { UPDATE_INDUSTRY_INFO } from '../../graphql/mutations/onboarding-mutations'
import { useMutation } from '@apollo/client'
import RoundedActionButton from '../inbox/common/rounded-action-button'

export const Footer = ({
  step,
  setStep,
  clicked,
  cardData,
  dispatch,
  selectedChannels,
  setClicked,
  user,
  getAccessTokenSilently,
  openConnectPhoneModal,
  channelConnected,
}) => {
  const { toggleNylasV3 } = useFlags()
  const ccuid = user.cc_uid

  const [token, setToken] = useState()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [updateIndustryInfo] = useMutation(UPDATE_INDUSTRY_INFO, {
    variables: { info: clicked?.name },
  })

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently()

        setToken(accessToken)
      } catch (err) {
        //
      }
    }
    if (step === 2) {
      getToken()
    }
  }, [step, getAccessTokenSilently])

  const handleClick = () => {
    if (step === 1) {
      localStorage.setItem('onboardingChannels', JSON.stringify(selectedChannels))
      setStep(step + 1)
    } else if (step === 2) {
      if (channelConnected?.length === selectedChannels?.length) {
        setStep(3)
        localStorage.setItem('onboardingSelectedChannel', '')
      }
      if (clicked.channel === 'video') {
        batch(() => {
          dispatch(setShowModal(true))
          dispatch(setModalContent(8))
        })
      } else if (clicked.channel === 'calls' || clicked.channel === 'phone') {
        openConnectPhoneModal()
      } else if (clicked.channel === 'webchat') {
        batch(() => {
          dispatch(setShowModal(true))
          dispatch(setModalContent(9))
        })
      } else if (clicked.channel === 'imap') {
        batch(() => {
          dispatch(setShowModal(true))
          dispatch(setModalContent(6))
        })
      } else {
        if (!clicked || clicked === null) {
          return
        }
        const clickedID = cardData.find(
          (card) => card.iconName === clicked.channel,
        ).id
        dispatch(handleConnectChannel({ clickedID, token, toggleNylasV3, ccuid }))
        if (clicked.channel !== 'imap') setLoading(true)
      }
    } else if (step === 3) {
      dispatch(setShowModal(true))
      dispatch(setModalContent(24))
      localStorage.setItem('onboardingSelectedChannel', '')
      localStorage.setItem('onboarding', false)
      updateIndustryInfo()
      if (channelConnected?.length > 0) {
        navigate('/inbox')
      } else {
        navigate('/')
      }
    }
  }

  const handleClickBack = () => {
    setStep(step - 1)
    localStorage.setItem('onboardingStep', step - 1)
  }

  const handleCompleteThisLaterClick = () => {
    localStorage.setItem('onboardingSelectedChannel', '')
    if (step === 3) {
      dispatch(setShowModal(true))
      dispatch(setModalContent(24))
      localStorage.setItem('onboarding', false)
      if (channelConnected?.length > 0) {
        navigate('/inbox')
      } else {
        navigate('/')
      }
    } else {
      setStep(3)
    }
  }

  useEffect(() => {
    if (step === 2 && !clicked?.channel) {
      setClicked(false)
    }
  }, [step, clicked])

  return (
    <div
      className={`w-full h-full max-h-[80px] flex items-center bg-white px-[32px] shadow-top-border z-50 ${
        (step === 2 || step === 3) && channelConnected?.length !== 3
          ? 'justify-between md:px-0'
          : 'justify-end'
      }`}
    >
      {(step === 2 || step === 3) && channelConnected?.length !== 3 && (
        <Button
          data-testid={'onboardingBackBtn'}
          onClick={handleClickBack}
          className="w-[92px] md:hidden"
          variant="secondary"
        >
          Back
        </Button>
      )}
      {(step === 2 || step === 3) && (
        <RoundedActionButton
          onClick={handleClickBack}
          className={`!w-[32px] !h-[32px] bg-[#ECEEF1] hidden md:${
            (step === 2 || step === 3) && channelConnected?.length !== 3 && 'flex'
          }`}
          type="regular"
          icon={{
            variant: 'arrowLeft',
            type: 'regular',
            width: '20',
            height: '20',
            color: '#808080',
          }}
          isMavIcon
        />
      )}

      <div className="flex gap-[16px]">
        {((step === 2 && !(channelConnected?.length === selectedChannels?.length)) ||
          step === 3) && (
          <Button
            data-testid={'onboardingCompleteLaterBtn'}
            onClick={handleCompleteThisLaterClick}
            className="w-[163px] "
            variant="secondary"
          >
            Complete This Later
          </Button>
        )}

        <Button
          data-testid={'onboardingNextOrConnectBtn'}
          onClick={handleClick}
          className="w-[92px]"
          variant="primary"
          disabled={
            (channelConnected?.length !== selectedChannels?.length && !clicked) ||
            (step === 3 && !clicked)
          }
        >
          {loading && token && clicked.channel !== 'imap' ? (
            <LoadingSpinner widthAndHeight={'15px'} />
          ) : step === 2 && clicked ? (
            'Connect'
          ) : (
            'Next'
          )}
        </Button>
      </div>
    </div>
  )
}
