import { useState, useEffect } from 'react'
import { GeneralCallsSettings } from './GeneralCallsSettings'
import { VoicemailSettings } from './VoicemailSettings'
import StartUsingCall from './StartUsingCall'
import CustomModal from '../../../components/common/CustomModal/Modal'
import AddMoreMinutes from '../../../components/calls/add-minutes/AddMoreMinutes'
import { useMediaQuery } from 'react-responsive'
import { useSelector, useDispatch } from 'react-redux'
import SuccessModal from '../../../components/common/modals/pause-sucess-modal/PauseSuccess'
import { ConnectPhoneModal } from '../../../components/common/modals/phone-certification-modal/connect-phone-modal/ConnectPhoneModal'
import {
  setModalContent,
  setDisableBackdropClick,
  setShowModal,
} from '../../../components/common/modals/slices/connectChannelsSlice'
export const CallsTab = () => {
  //to be only true if number is already assigned or it will be false
  const { phoneNumber } = useSelector((state) => state.contacts.contacts)
  const [isCallNumberAssigned, setIsCallNumberAssigned] = useState(
    phoneNumber ? true : false,
  )
  const [openAddMoreMinutes, setOpenAddMoreMinutes] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 835 })
  const dispatch = useDispatch()
  const { modalContent, showModal } = useSelector((state) => state.connectChannels)
  const handleClose = () => {
    setOpenAddMoreMinutes(false)
    setOpenSuccessModal(true)
  }
  const handleClosePhoneModal = () => {
    dispatch(setDisableBackdropClick(false))
    dispatch(setModalContent(1))
    dispatch(setShowModal(false))
  }

  useEffect(() => {
    if (phoneNumber) {
      setIsCallNumberAssigned(true)
    }
  }, [phoneNumber])

  return (
    <>
      {isMobile ? (
        <div className="grid grid-row h-[800px] justify-center">
          {!isCallNumberAssigned && <StartUsingCall />}
          {isCallNumberAssigned && <GeneralCallsSettings isMobile />}
        </div>
      ) : (
        <div className="grid grid-row">
          {isCallNumberAssigned ? (
            <>
              <GeneralCallsSettings setOpenAddMoreMinutes={setOpenAddMoreMinutes} />
              <VoicemailSettings />
            </>
          ) : (
            <StartUsingCall />
          )}
        </div>
      )}

      <CustomModal
        isModalForm={true}
        showTitle={true}
        heading="Add More Minutes"
        hasCrossIcon={true}
        isOpen={openAddMoreMinutes}
        setIsOpen={setOpenAddMoreMinutes}
        close={() => setOpenAddMoreMinutes(false)}
        width={isMobile ? '94%' : '1024px'}
      >
        <AddMoreMinutes
          setOpenAddMoreMinutes={setOpenAddMoreMinutes}
          setOpenSuccessModal={setOpenSuccessModal}
          handleClose={handleClose}
        />
      </CustomModal>

      <CustomModal
        isModalForm={true}
        showTitle={false}
        hasCrossIcon={false}
        isOpen={openSuccessModal}
        setIsOpen={setOpenSuccessModal}
        width={isMobile ? '94%' : '500px'}
      >
        <SuccessModal
          modalType="Success"
          modalHeaderMessage="Congratulations, payment completed"
          modalBodyMessage="Monthly minutes have been added to your Plan. Need more? Visit Settings or upgrade your plan!"
          setOpenSuccessModal={setOpenSuccessModal}
          showCross={true}
        />
      </CustomModal>
      {modalContent === 7 ? (
        <ConnectPhoneModal
          showModal={showModal}
          setShowModal={handleClosePhoneModal}
        />
      ) : null}
    </>
  )
}
