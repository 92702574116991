import RecentAndVoicemail from './recent-and-voicemail-content/RecentAndVoicemailMain'
import { useMediaQuery } from 'react-responsive'

export const Voicemails = ({ setContactInfoView, loading }) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })

  // The calc function is to calculate the height of the container based on the elements above it. They change if mobile
  return (
    <div
      id="contacts-calls"
      className="overflow-hidden"
      style={{ height: isMobile ? 'calc(100vh - 116px)' : 'calc(100vh - 146px)' }}
    >
      <RecentAndVoicemail
        loading={loading}
        tabSelected="voicemail"
        setContactInfoView={setContactInfoView}
      />
    </div>
  )
}

export const Recents = ({ contactInfoView, setContactInfoView, loading }) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })

  // The calc function is to calculate the height of the container based on the elements above it. They change if mobile
  return (
    <div
      id="contacts-calls"
      className="overflow-hidden"
      style={{ height: isMobile ? 'calc(100vh - 116px)' : 'calc(100vh - 146px)' }}
    >
      <RecentAndVoicemail
        loading={loading}
        tabSelected="recent"
        contactInfoView={contactInfoView}
        setContactInfoView={setContactInfoView}
      />
    </div>
  )
}
