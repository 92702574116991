import { useState } from 'react'
import { useFormContext } from '../../../right-panel/user-profile-and-activity/contexts/form-context'
import { useSelector } from 'react-redux'
import { useError } from './hooks/use-error'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { DELETE_MESSAGES } from '../../../../graphql'
import { DELETE_MESSAGES_WITH_MERGE } from '../../../../graphql'
import { setUpdatedContactMergeNotification } from '../../../inbox/slices/inboxSlice'
import { UPDATE_CONTACT } from '../../../../graphql'
import { FETCH_CONTACT_BY_THREAD } from '../../../../graphql'
import { addCountryCodeToPhone } from './utils/add-country-code-to-phone'
import { shapeContactForSubmission } from './utils/shape-contact-for-submission'
import { USER_FRIENDLY_ERRORS } from './utils/user-friendly-errors'
import EditContactFormCalls from './edit-contact-form-calls/edit-contact-form-calls'
import ContactUpdateConfirmationPanel from './contact-update-confirmation-panel/contact-update-confirmation-panel'
import { FETCH_CONTACT_WITH_PHONE } from '../../../../graphql'
import { useLazyQuery } from '@apollo/client'
import { setContactLists, updateSelectedContact } from '../../../../redux-toolkit'
import { GET_MESSAGES_BY_PHONE_CHANNEL } from '../../../../graphql'
import {
  setCalls,
  setPrepopulatedNumber,
  setVoicemails,
} from '../../slices/callSlice'

export const SaveButton = ({ className, onClick, enabled, ...rest }) => {
  return (
    <button
      aria-label="Save Contact Form"
      type="submit"
      onClick={onClick}
      className={`${className} ${!enabled && 'pointer-events-none'}`}
      {...rest}
    >
      <p
        className={`text-[14px] font-open-sans font-semibold transition-all duration-300 ${
          enabled ? 'text-[#057AFF]' : 'text-[#c4c4c4]'
        }`}
      >
        Save
      </p>
    </button>
  )
}

const EditContactPanel = ({ onBackButtonClick }) => {
  const dispatch = useDispatch()

  const { selectedContact: contact } = useSelector((state) => state.contacts)
  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)

  // OPTIONS: 'form', 'confirm-edit-contact'
  const [selectedPanel, setSelectedPanel] = useState('form')
  const [userConfirmedSubmission, setUserConfirmedSubmission] = useState(false)
  const [submissionIsLoading, setSubmissionIsLoading] = useState(false)

  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const { showError, errorMessage, updateErrorMessage, hideErrorMessage } =
    useError()

  const { emailIsDirty, phoneIsDirty, isEmailOrPhoneDirty, handleSubmit } =
    useFormContext()

  const [deleteMessages] = useMutation(DELETE_MESSAGES)

  const [deleteMessagesWithMerge] = useMutation(DELETE_MESSAGES_WITH_MERGE, {
    onCompleted: () => {
      dispatch(setUpdatedContactMergeNotification(contact.contactsk1))
    },
  })

  const [getContacts] = useLazyQuery(FETCH_CONTACT_WITH_PHONE, {
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  })

  const [getRecentCalls] = useLazyQuery(GET_MESSAGES_BY_PHONE_CHANNEL, {
    fetchPolicy: 'network-only',
  })

  const [getVoicemails] = useLazyQuery(GET_MESSAGES_BY_PHONE_CHANNEL, {
    fetchPolicy: 'network-only',
  })

  const reinitContactsList = async () => {
    const fetchedContactsData = await getContacts()
    const queriedContacts = fetchedContactsData.data.queryContactsWithPhoneNumber

    dispatch(
      setContactLists({
        sortOrder: 'asc',
        queriedContacts: queriedContacts,
        countryISO: countryCode,
      }),
    )
  }

  const reinitCallsLists = async () => {
    const fetchedRecentCalls = await getRecentCalls({
      fetchPolicy: 'network-only',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })

    const fetchedVoicemails = await getVoicemails({
      fetchPolicy: 'network-only',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })

    const recentCalls = await fetchedRecentCalls.data.queryMessagesByPhoneChannel
    const voicemails = await fetchedVoicemails.data.queryMessagesByPhoneChannel

    dispatch(setCalls(recentCalls))
    dispatch(setVoicemails(voicemails))
  }

  const handleDeleteMessages = async (newContact) => {
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessages({
      variables: {
        VendorID: newContact.phone === contact.phone ? 1 : 2,
        thread_id: contact.contactsk1,
      },
    })

    // use setTimeout to refetchAllContactInformation, BE needs time to fully update contacts and delete messages
    setTimeout(async () => {
      await updateContactInfoAcrossUI()
      setSubmissionIsLoading()
      onBackButtonClick()
    }, 3000)
  }

  const handleDeleteMessagesAndMergeContacts = async (newContact, mergeContact) => {
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessagesWithMerge({
      variables: {
        VendorID: newContact.phone === contact.phone ? 1 : 2,
        thread_id: contact?.contactsk1,
        body: mergeContact.body,
        contactsk1: mergeContact.contactsk1,
        contactsk2: mergeContact.contactsk2,
        contactsk3: mergeContact.contactsk3,
        pk1: mergeContact.pk1,
        sk1: mergeContact.sk1,
      },
    })

    // use setTimeout to refetchAllContactInformation, BE needs time to fully update contacts and delete messages
    setTimeout(async () => {
      await updateContactInfoAcrossUI()
      setSubmissionIsLoading()
      onBackButtonClick()
    }, 3000)
  }

  const updateContactInfoAcrossUI = async (newsk1, phone) => {
    await reinitContactsList()
    await reinitCallsLists()

    dispatch(
      updateSelectedContact({
        sk1: newsk1,
      }),
    )

    if (phone) dispatch(setPrepopulatedNumber(phone))
  }

  const [updateContact] = useMutation(UPDATE_CONTACT, {
    errorPolicy: 'all',
    refetchQueries: () => [
      {
        query: FETCH_CONTACT_BY_THREAD,
        variables: { threadid: contact.thread_id },
      },
    ],
  })

  const updateContactInBackend = async (newContact) => {
    const UPDATED_CONTACT = { ...newContact }

    if (UPDATED_CONTACT.phone.length) {
      UPDATED_CONTACT.phone = addCountryCodeToPhone(
        UPDATED_CONTACT.phone,
        countryCode,
      )
    }

    // Submission shape if all values are valid: { body: '', contactsk2: '', contactsk3: '', sk1: ''}
    const shapedContact = shapeContactForSubmission(UPDATED_CONTACT, contact.sk1)

    const response = await updateContact({
      variables: shapedContact,
    })

    return response
  }

  const onSubmit = async (newContact) => {
    setSubmissionIsLoading(true)
    hideErrorMessage()

    if (isEmailOrPhoneDirty() && !userConfirmedSubmission) {
      setSelectedPanel('confirm-edit-contact')
      setSubmissionIsLoading(false)
      return
    }

    try {
      const res = await updateContactInBackend(newContact)
      const updatedContactFromDb = res.data.update_contact

      const errorExists = res?.errors?.length

      if (errorExists) {
        const error = {
          message: res.errors[0].message,
          code: res.errors[0].errorType,
        }

        const mergeContact = updatedContactFromDb.mergeContact

        if (error.code === 'ERR009') {
          if (mergeContact) {
            // If getting back ERR009 WITH mergeContact need to run deleteMessages with mergeContact
            await handleDeleteMessagesAndMergeContacts(newContact, mergeContact)
          } else {
            // If getting back ERR009 WITHOUT mergeContact need to run deleteMessages without mergeContact
            await handleDeleteMessages(newContact)
          }
        } else {
          // You can update contact first and/or last name and still reach this else statement.

          // This error means failure to update in Vcita
          if (error.code === 'ERRO14') {
            await updateContactInfoAcrossUI(
              updatedContactFromDb.sk1,
              newContact.phone,
            )
            onBackButtonClick()
            return
          }

          if (error.message.length) {
            updateErrorMessage(USER_FRIENDLY_ERRORS[error.code])
          }

          await updateContactInfoAcrossUI(updatedContactFromDb.sk1, newContact.phone)
          setSubmissionIsLoading(false)
          onBackButtonClick()
          return
        }
      } else {
        await updateContactInfoAcrossUI(updatedContactFromDb.sk1, newContact.phone)
        onBackButtonClick()
      }
    } catch (err) {
      setSubmissionIsLoading(false)
      updateErrorMessage('Contact changes not saved. Please try again.')
    }
  }

  return (
    <div className={'relative w-full flex flex-col h-full m-auto'}>
      {selectedPanel === 'form' && (
        <EditContactFormCalls
          onBackButtonClick={onBackButtonClick}
          submissionIsLoading={submissionIsLoading}
          onSubmit={onSubmit}
          showError={showError}
          hideErrorMessage={hideErrorMessage}
          errorMessage={errorMessage}
          contact={{
            username: contact.username,
            pictureUrl: contact.pictureUrl,
            phone: contact.phone,
          }}
        />
      )}

      {selectedPanel === 'confirm-edit-contact' && (
        <ContactUpdateConfirmationPanel
          variant={
            (emailIsDirty() && phoneIsDirty() && 'both') ||
            (emailIsDirty() && 'email') ||
            (phoneIsDirty() && 'phone')
          }
          onCancelClick={() => setSelectedPanel('form')}
          onConfirmClick={() => {
            setSelectedPanel('form')
            setUserConfirmedSubmission(true)
            handleSubmit(onSubmit)
          }}
          isMobile={false}
        />
      )}
    </div>
  )
}

export default EditContactPanel
