import { ContactsListEmptyState } from '@icons'
import Contact from './contact'
import { setPhoneValue } from '../slices/callSlice'
import {
  filterContactsByPhone,
  filterContactsByNameOrEmail,
} from '../../../redux-toolkit'
import { useDispatch } from 'react-redux'
import { useRedux } from '../../../hooks'
import { Button } from '@thryvlabs/maverick'

const ContactsListEmptyStatePanel = ({ className }) => {
  return (
    <div
      className={`${className} w-full h-[60px] flex gap-3 py-2 px-4 items-center justify-start mb-2 bg-[#ECEEF1]`}
    >
      <ContactsListEmptyState />

      <span className="font-open-sans text-sm font-normal text-[#231f20]">
        High Thryv!
      </span>
    </div>
  )
}

const ContactsList = ({
  show,
  className,
  setMobileContactSideSheetOpen,
  setContactInfoView,
  setShowKeypad,
}) => {
  const dispatch = useDispatch()
  const [{ contacts }] = useRedux('contacts')
  const { contactsList, filteredContacts } = contacts

  const showSeeAllButton =
    filteredContacts.length < contactsList.length && filteredContacts.length > 0

  const userOnlyHasThryvSupportContact =
    contactsList.length === 1 && contactsList[0].username === 'Thryv Support Center'

  return show ? (
    <div
      className={`${className} overflow-y-auto no-scrollbar border-t border-[#F1F1F5]`}
      style={{ height: 'calc(100vh - 375px)' }}
    >
      <ul>
        {filteredContacts.map((contact, index) => (
          <li key={index}>
            <Contact
              contact={{ ...contact }}
              setMobileContactSideSheetOpen={setMobileContactSideSheetOpen}
              setContactInfoView={setContactInfoView}
            />
          </li>
        ))}
      </ul>

      {showSeeAllButton ? (
        <Button
          variant="text"
          level={1}
          type="button"
          onClick={() => {
            dispatch(setPhoneValue(''))
            dispatch(filterContactsByPhone(''))
            dispatch(filterContactsByNameOrEmail(''))
            setShowKeypad(false)
          }}
          className="w-full py-[20px] font-montserrat text-sm text-center hover:bg-[#057aff0D] transition-all duration-300"
        >
          Show All
        </Button>
      ) : null}

      {userOnlyHasThryvSupportContact ? (
        <p className="font-montserrat text-sm text-[#808080] text-center pt-[100px]">
          Your contacts will appear here.
        </p>
      ) : null}
    </div>
  ) : (
    <ContactsListEmptyStatePanel className={''} />
  )
}

export default ContactsList
