import { CommandCenterIconResponsive } from '../common/modals/connect-channels-modal/ModalComponent.styles'
import { Header, ParagraphText, Icon } from '@thryvlabs/maverick'
import { cloneDeep } from '@apollo/client/utilities'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { TooltipWithCenteredArrow } from '../common/tooltip-with-centered-arrow'

const OnboardingChannelSelector = ({
  selectedChannels,
  setSelectedChannels,
  setClicked,
}) => {
  const priority = {
    calls: 1,
    gmail: 2,
    facebookMessenger: 3,
    instagram: 4,
    webchat: 5,
    video: 6,
    outlook: 7,
    imap: 8,
    hotmail: 9,
    yahoo: 10,
    icloud: 11,
    microsoft365: 12,
    default: 100,
  }

  const { cardData, channelInfo } = useSelector((state) => state.connectChannels)

  const connectedChannels = channelInfo.map((x) => x.Provider)

  const [isHovered, setIsHovered] = useState(false)
  const [hoveredIcon, setHoveredIcon] = useState('')
  const sortedCardDataByPopularity = cloneDeep(cardData)

  sortedCardDataByPopularity.sort((a, b) => {
    return (
      (priority[a.iconName] || priority.default) -
      (priority[b.iconName] || priority.default)
    )
  })

  sortedCardDataByPopularity.find((item) => item.iconName === 'calls').iconName =
    'phone'

  useEffect(() => {
    if (selectedChannels?.length) {
      setClicked(true)
    } else {
      setClicked(false)
    }
  }, [selectedChannels])

  return (
    <div className="flex flex-col items-center text-center justify-center bg-white ">
      <Header
        fontWeight="semibold"
        variant="h1"
        className="mb-5 w-[524px] md:w-[254px] md:text-left"
      >
        Select three apps to connect.
      </Header>
      <ParagraphText
        variant="reg"
        className="w-[508px] md:w-[274px] text-center md:text-left font-normal leading-[22px]"
      >
        In minutes, you’ll be able to read and send messages from all three apps
        within Command Center.
      </ParagraphText>

      <div className="grid grid-cols-3 md:grid-cols-2 w-[388px] md:w-[300px] h-[504px] pt-[24px]">
        {sortedCardDataByPopularity.map((card, idx) => (
          <div
            className="flex flex-col items-center justify-evenly h-[88px] pt-2 w-full"
            key={idx}
            data-testid={`channel-${card.iconName}`}
          >
            <TooltipWithCenteredArrow
              variant="top"
              title="This channel is already connected"
              width="max-content"
              disableVisibleOnClick={true}
              visible={connectedChannels.includes(card.iconName)}
            >
              <div
                className={`
                flex items-center justify-center rounded-[8px] p-1 
                ${
                  selectedChannels?.includes(card.iconName)
                    ? 'border-[3px] border-[#EE7B45] bg-[#EE7B45] bg-opacity-[25%] cursor-' +
                      (connectedChannels?.includes(card.iconName)
                        ? 'default'
                        : 'pointer')
                    : 'border-transparent cursor-pointer'
                } 
                ${
                  selectedChannels?.length < 3
                    ? 'hover:border-[#EE7B45] hover:bg-[#EE7B45] hover:bg-opacity-[25%]'
                    : ''
                }
              `}
                onMouseEnter={() => {
                  if (selectedChannels?.length < 3) {
                    setIsHovered(true)
                    setHoveredIcon(card.iconName)
                  }
                }}
                onMouseLeave={() => {
                  setIsHovered(false)
                  setHoveredIcon('')
                }}
              >
                <div
                  data-testid={`channelSelectorBtn-${card.iconName}`}
                  className="flex flex-col gap-[14px] h-[100px] w-[100px] md:h-[70px] md:w-[70px] md:gap-0 items-center pt-3"
                  onClick={() => {
                    if (connectedChannels.includes(card.iconName)) {
                      return
                    }
                    if (selectedChannels?.includes(card.iconName)) {
                      setSelectedChannels(
                        selectedChannels?.filter(
                          (channel) => channel !== card.iconName,
                        ),
                      )
                    } else if (
                      selectedChannels?.length < 3 &&
                      !selectedChannels?.includes(card.iconName)
                    ) {
                      setSelectedChannels([...selectedChannels, card.iconName])
                    }
                  }}
                >
                  {isHovered &&
                  hoveredIcon === card.iconName &&
                  !selectedChannels?.includes(card.iconName) ? (
                    <Icon
                      type="regular"
                      variant="circleCheck"
                      width={48}
                      height={48}
                      color={'#FF5000'}
                    />
                  ) : (
                    <>
                      <CommandCenterIconResponsive
                        type={card.iconType}
                        variant={card.iconName === 'phone' ? 'calls' : card.iconName}
                        isWelcomeModal={false}
                        width={'48px'}
                        height={'48px'}
                      />
                      <ParagraphText variant="sm">{card.ButtonText}</ParagraphText>
                    </>
                  )}
                </div>
              </div>
            </TooltipWithCenteredArrow>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OnboardingChannelSelector
