import { useState, useRef, useEffect } from 'react'
import '../message-component.css'
import { MessageHeader } from './message-header'
import { MessageFooter } from './message-footer'
import { MessageSocialIcon } from '../message-social-icon'
import ActionButtonsMenu from '../action-buttons-menu/action-buttons-menu'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import MessageSection from './sections/message-section'
import CallAndVoicemailSection from './sections/calls-and-voicemail-section'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import formatContactData from '../../../hooks/formatContactData'
import useCheckVoicemailExistence from '../../../hooks/useCheckVoicemailExistence'
import { useSelector } from 'react-redux'

const MessageBoxContainer = ({ children, className, onClickOutside, ...rest }) => {
  const messageBoxRef = useRef(null)

  useClickOutside(() => {
    onClickOutside()
  }, messageBoxRef)

  return (
    <div
      className={`
        relative bg-[white] max-w-[465px] md:max-w-[350px] border-solid border border-[#EFEFEF] rounded-bl-xl rounded-br-xl duration-300 transition-all

        ${className}
        `}
      {...rest}
      ref={messageBoxRef}
    >
      {children}
    </div>
  )
}

const footerMethods = {
  getFooterText: (messageType, isVoicemailExist, isLoadingAudio, footerText) => {
    switch (messageType) {
      case 'VOICEMAIL':
        if (!isVoicemailExist) {
          return 'This voicemail has expired'
        }
        return ``
      default:
        return footerText
    }
  },

  getFooterVariant: (
    messageType,
    isVoicemailExist,
    isLoadingAudio,
    footerVariant,
  ) => {
    switch (messageType) {
      case 'VOICEMAIL':
        if (!isVoicemailExist) {
          return 'warning'
        }
        return 'default'
      default:
        return footerVariant
    }
  },
}

export const IncomingMessage = ({
  body,
  className,
  hideHeader,
  user,
  message,
  actionButtons,
  emailSnippet,
  translations,
  footer,
  incomingId,
  labels,
  selectedThreadId,
  refetchPins,
  refetchMessages,
  from,
  messageContentRef,
  positionLabelMenuAbove,
}) => {
  const [showTranslation, setShowTranslation] = useState(false)
  const visibleMenuRef = useRef('')
  const [isDeletedMessage, setIsDeletedMessage] = useState(
    message.text === 'Message deleted.' || emailSnippet === 'Message deleted.',
  )
  const agentLanguage = useSelector((state) => state.user.language)
  const translationDefault = useSelector((state) => state.user.translate)

  const [showActionButtons, setShowActionButtons] = useState(false)

  const handleMouseEnter = () => {
    if (isDeletedMessage) return
    setShowActionButtons(true)
  }

  const handleMouseLeave = () => {
    if (visibleMenuRef.current === '') setShowActionButtons(false)
  }

  const messageTitleFrom = message.type === 'phone' ? formatContactData(from) : from
  const useBody =
    body === 'Message deleted.' ? true : body && emailSnippet === null ? true : false
  const msgText = useBody ? body : emailSnippet
  const [messageText, setMessageText] = useState(msgText)
  const [messageTitle, setMessageTitle] = useState(
    message.title ? message.title : '',
  )
  const swapTranslation = () => {
    setShowTranslation((prev) => !prev)
  }

  let translatedMessage, translatedSubject
  if (translations) {
    const parsedTranslations = JSON.parse(translations)
    translatedMessage =
      parsedTranslations[agentLanguage]?.[useBody ? 'body' : 'email_snippet']
    translatedSubject = parsedTranslations[agentLanguage]?.email_subject
  }

  useEffect(() => {
    setShowTranslation(translationDefault)
  }, [translationDefault])

  const audioInfo = useCheckVoicemailExistence(message)
  const { isVoicemailExist, isLoadingAudio } = audioInfo
  useEffect(() => {
    if (messageText && messageText === 'Message deleted.') {
      setMessageTitle('')
      setIsDeletedMessage(true)
      return messageText
    }
  }, [messageText, isDeletedMessage])
  // ignore those duplicate voicemails without a link
  if (message.variant === 'VOICEMAIL' && message.text.indexOf('link') === -1) {
    return null
  }
  return (
    <div id={incomingId} className={`message-container ${className}`}>
      {!hideHeader && (
        <MessageHeader
          name={/\d{10}/.test(user.fullName) ? 'Unknown Contact' : user.fullName}
          imageUrl={user.imageUrl}
          channelAddress={user.channelAddress}
        />
      )}

      <div className={`px-2 pb-1 pt-2`}>
        <MessageBoxContainer
          className={message.type === 'CALL' && 'w-fit'}
          isPinned={message.isPinned}
          disableButtons={actionButtons.allDisabled}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClickOutside={() => setShowActionButtons(false)}
        >
          <div className="flex gap-2.5 absolute -right-2.5 -top-4 items-center h-9">
            <ActionButtonsMenu
              positionLabelMenuAbove={positionLabelMenuAbove}
              setMessageText={setMessageText}
              show={showActionButtons && actionButtons.clickHandlers ? true : false}
              variant={message?.variant}
              type={message?.type}
              disableButtons={actionButtons?.allDisabled}
              showPin={message?.isPinned}
              clickHandlers={actionButtons.clickHandlers}
              refetchMessages={refetchMessages}
              refetchPins={refetchPins}
              labels={message?.labels}
              sk1={message?.sk1}
              pk1={message?.pk1}
              threadpk={message?.threadpk}
              chanpk={message?.chanpk}
              onVisibleMenuChange={(visibleMenu) =>
                (visibleMenuRef.current = visibleMenu)
              }
              incomingId={incomingId}
              messageSk1={message.sk1}
              selectedThreadId={selectedThreadId}
              messageContentRef={messageContentRef}
            />
            {from ? (
              <TooltipWithCenteredArrow
                variant="top"
                width="max-content"
                title={messageTitleFrom}
              >
                <MessageSocialIcon variant={message.type} />
              </TooltipWithCenteredArrow>
            ) : (
              <MessageSocialIcon variant={message.type} />
            )}
          </div>
          {(message.variant === 'MSG' || message.variant === 'SMS') && (
            <MessageSection
              message={message}
              messageText={
                showTranslation && translatedMessage
                  ? translatedMessage
                  : messageText
              }
              setMessageTitle={setMessageTitle}
              messageTitle={
                showTranslation && translatedSubject
                  ? translatedSubject
                  : messageTitle
              }
              setIsDeletedMessage={setIsDeletedMessage}
              isDeletedMessage={isDeletedMessage}
              messageContentRef={messageContentRef}
            />
          )}
          {(message.variant === 'CALL' || message.variant === 'VOICEMAIL') && (
            <CallAndVoicemailSection message={message} audioInfo={audioInfo} />
          )}
        </MessageBoxContainer>
      </div>
      <MessageFooter
        variant={footerMethods.getFooterVariant(
          message.variant,
          isVoicemailExist,
          isLoadingAudio,
          footer.variant,
        )}
        timestamp={message.timestamp}
        text={footerMethods.getFooterText(
          message.variant,
          isVoicemailExist,
          isLoadingAudio,
          footer.text,
        )}
        id={incomingId}
        labels={isDeletedMessage ? [] : labels}
        isDeletedMessage={isDeletedMessage}
        showTranslation={showTranslation}
        showSwapTranslation={!!translatedMessage}
        swapTranslation={swapTranslation}
      />
    </div>
  )
}
