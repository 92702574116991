import formatContactData from '../../../inbox/hooks/formatContactData'

// Components
import RecentCalls from '../../../calls/contacts/recentcall-section/RecentCalls'
import { RemoveContactDetail } from '../../../command-center-icon/icons'
import { useState } from 'react'

const GreyHorizontalLine = ({ className }) => (
  <div className={`${className} h-[1px] bg-[#E3E6E8] w-full`}></div>
)

const Header = ({ children }) => (
  <p className="font-semibold text-sm text-[#231F20] font-montserrat mb-[4px]">
    {children}
  </p>
)

const Text = ({ children }) => (
  <p className="font-open-sans font-normal text-sm text-[#4D4D4D] truncate">
    {children}
  </p>
)

const EditButton = ({ onClick, ...rest }) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
      {...rest}
      type="button"
    >
      <RemoveContactDetail color={isHover ? '#057AFF' : '#A3A5A7'} />
    </button>
  )
}

const RightPanelUserProfile = ({ className, contact, onEditClick = () => {} }) => {
  const { phone, email, threadpk } = contact

  const phoneNumber = formatContactData(phone) || ''

  const renderDetailsEmptyState = (
    <p className="font-open-sans font-normal text-sm text-[#4D4D4D]">--</p>
  )

  return (
    <div className={className}>
      {/* CONTACT INFORMATION SECTION */}
      <address className="text-left not-italic flex flex-col gap-[16px]">
        <div className="w-full flex justify-between items-center">
          <div>
            <Header>Mobile</Header>
            <Text>{phoneNumber.length ? phoneNumber : renderDetailsEmptyState}</Text>
          </div>

          <EditButton
            onClick={() => onEditClick('mobile')}
            data-testid="edit-phone-btn"
            aria-label="Edit Phone Number"
          />
        </div>

        <div className="w-full flex justify-between items-center">
          <div className="truncate">
            <Header>Email</Header>
            <Text>{email.length ? email : renderDetailsEmptyState}</Text>
          </div>

          <EditButton
            onClick={() => onEditClick('email')}
            data-testid="edit-email-btn"
            aria-label="Edit Email"
          />
        </div>
      </address>

      <GreyHorizontalLine className="my-[24px]" />

      {/* RECENT CALLS SECTION */}
      <div>
        <RecentCalls show={Boolean(phone)} threadpk={threadpk} />
      </div>
    </div>
  )
}

export default RightPanelUserProfile
