import { useState, useRef, forwardRef, useEffect, useContext } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import DOMPurify from 'dompurify'
import '../message-component.css'
import { ParagraphText } from '@thryvlabs/maverick'
import { MessageSocialIcon } from '../message-social-icon'
import { UploadedFile as Attachment } from '../../message-compose/attachments/uploaded-files'
import { BccOrRecipientsSection } from '../../common/message-recipient-section/message-recipient-section'
import { LoadingDots } from '../../../../common/loading-dots'
import './outgoing-message.css'
import { OutgoingMessageContext } from '../../../../../context/message-context'
import { ShowMoreButton } from '../../message-compose/compose-header/ccSection'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import phoneFormatter from '../../../../../utils/phoneFormatter'
import findYouTubeVideoId from '../../../common/youtube-embeds/findYoutubeVideoId'
import YouTubeEmbed from '../../../common/youtube-embeds/YoutubeEmbeded'
import { handleReloadMessage, setError } from '../../../slices/inboxSlice'

import {
  setMessageInModal,
  setViewFullMessageOpen,
} from '../../../slices/inboxSlice'

const MessageBoxContainer = forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <div
      className={`${className} relative max-w-[465px] md:max-w-[300px] bg-[#808080] border-solid border border-[#EFEFEF] rounded-bl-xl rounded-br-xl duration-300 transition-all`}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  )
})

export const PlaceholderMessage = ({
  className,
  message,
  user,
  body,
  draftMessageData,
  confirmAttachment,
}) => {
  const dispatch = useDispatch()
  const messageBoxRef = useRef(null)

  const [willClamp, setWillClamp] = useState(false)
  const msgRef = useRef(null)
  const [showMore, setShowMore] = useState(false)
  const outgoingMessage = useContext(OutgoingMessageContext)
  const [isDeletedMessage, setIsDeletedMessage] = useState(
    body === 'Message deleted.',
  )
  const { selectedChannel } = useSelector((s) => s.messageCompose)
  const { isLoading } = useSelector((state) => state.inbox)
  const { sendButtonClicked } = useSelector((state) => state.messageCompose)
  const errorStatus = useSelector((state) => state.inbox.error.status)
  const errorMessage = useSelector((state) => state.inbox.error.message)
  const [messageText] = useState(body || draftMessageData?.email_snippet)
  const [messageTitle, setMessageTitle] = useState(
    message.title || draftMessageData?.email_subject,
  )

  let youtubeEmbedComponent = null
  if (message.text) {
    const youTubeVideoId = findYouTubeVideoId(message.text)
    if (youTubeVideoId) {
      // Make sure youTubeVideoId is not null or undefined
      youtubeEmbedComponent = <YouTubeEmbed videoId={youTubeVideoId} />
    }
  }

  const sendMessageLoadingAnimation =
    isLoading || (sendButtonClicked && !errorStatus) ? (
      <div className="w-full h-[65px] flex justify-end pr-[40px] gap-2 ">
        {' '}
        <span className="text-[#808080] font-normal text-[12px] leading-[18px]">
          Sending
        </span>
        <LoadingDots className="mt-1" />
      </div>
    ) : null

  const displayErrorMessage =
    errorMessage && errorStatus ? (
      <div className="flex justify-end pr-[55px]">
        <div className="h-[50px] items-center mt-0  flex gap-1">
          <span className="font-montserrat font-normal text-[12px] text-notification-red ">
            Send failed.
          </span>

          <span
            onClick={() => {
              dispatch(handleReloadMessage(true))
              dispatch(setError({ status: false, message: '' }))
            }}
            className={
              'font-open-sans font-normal text-[12px] leading-[18px] text-[#5378FC] hover:underline cursor-pointer'
            }
          >
            Retry
          </span>
        </div>
      </div>
    ) : null

  const handleShowMoreClick = () => {
    setShowMore(!showMore)
  }

  useEffect(() => {
    setWillClamp(
      msgRef.current?.scrollHeight > msgRef.current?.clientHeight ||
        message?.text?.length > 150,
    )
  }, [msgRef])

  const firstTwoAttachments =
    confirmAttachment?.slice(0, 2) || draftMessageData?.attachments?.slice(0, 2)

  const useHTML = body.startsWith('<')
  const underlineLink = (htmlText) => {
    return htmlText?.replace(
      /<a.*?href="([^"]*)".*?>(.*?)<\/a>/gi,
      '<a href="$1" target="_blank" rel="noopener noreferrer"><span class="underline">$2</span></a>',
    )
  }

  const formattedHTML = underlineLink(messageText)

  useEffect(() => {
    if (messageText && messageText === 'Message deleted.') {
      setMessageTitle('')
      setIsDeletedMessage(true)
      return messageText
    }
  }, [messageText, isDeletedMessage])

  const renderMessageContent = (
    <>
      <div className="m-4">
        <div ref={msgRef} className="mb-2 line-clamp-3">
          <ParagraphText
            variant="reg"
            className="font-['Montserrat'] !font-semibold mb-2"
            color="white"
          >
            {messageTitle}
          </ParagraphText>

          {!useHTML ? (
            <ParagraphText variant="sm" color="white">
              {messageText}
            </ParagraphText>
          ) : (
            <ParagraphText
              variant="sm"
              color="white"
              className="outgoing-message-inner-html"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formattedHTML) }}
            />
          )}
        </div>
        {youtubeEmbedComponent}

        {!isDeletedMessage && willClamp && (
          <button
            className="text-sm font-open-sans font-semibold text-white underline pt-[5px]"
            onClick={() => {
              batch(() => {
                dispatch(setViewFullMessageOpen(true))
                dispatch(setMessageInModal(outgoingMessage.body))
              })
            }}
          >
            View entire message
          </button>
        )}
      </div>

      {!isDeletedMessage &&
      (message?.emailRecipients.cc?.length > 0 ||
        draftMessageData?.email_cc?.length > 0 ||
        message?.emailRecipients.bcc?.length > 0 ||
        draftMessageData?.email_bcc?.length > 0 ||
        confirmAttachment?.length > 0) ? (
        <div className="bg-white flex flex-col p-2 pb-0 items-left rounded-b-[10px]">
          <div className="pl-3">
            {message?.emailRecipients.cc &&
            message?.emailRecipients.cc?.length >= 1 ? ( //checking that there's something to render inside cc array
              <BccOrRecipientsSection
                emailRecipient={
                  message?.emailRecipients.cc || draftMessageData?.email_cc
                }
                type="cc"
              />
            ) : null}
          </div>
          <div className="pl-3">
            {message?.emailRecipients.bcc &&
            message?.emailRecipients.bcc?.length >= 1 ? (
              <BccOrRecipientsSection
                emailRecipient={
                  message?.emailRecipients.bcc || draftMessageData?.email_bcc
                }
                type="bcc"
              />
            ) : null}
          </div>

          {confirmAttachment?.length > 2 ||
          (draftMessageData?.attachments > 2 && !showMore) ? (
            <div className=" flex flex-row flex-wrap bg-white p-3 rounded-b-[10px] border-t border-[#eceef1]">
              {firstTwoAttachments?.map((file, i) => (
                <Attachment
                  file={JSON.parse(file.body)}
                  //   getAttachment={getAttachmentLink}
                  fileId={file.sk1}
                  key={i}
                />
              ))}
              <ShowMoreButton
                className="ml-1"
                number={
                  confirmAttachment.length - 2 ||
                  draftMessageData?.attachments?.length - 2
                }
                onClick={() => handleShowMoreClick()}
                showMore={showMore}
              />
            </div>
          ) : confirmAttachment.length >= 2 ||
            draftMessageData?.attachments?.length >= 2 ? (
            <div className=" flex flex-row flex-wrap bg-white p-3 rounded-b-[10px] border-t border-[#eceef1]">
              {confirmAttachment?.map((file, i) => (
                <Attachment
                  file={JSON.parse(file.body)}
                  //   getAttachment={getAttachmentLink}
                  fileId={file.sk1}
                  key={i}
                />
              ))}
              {/* fix this */}
              {/* message.attachments?.length > 2 && showMore */}
              {/* <ShowMoreButton
                className="ml-[70px] mt-[11px]"
                number={message.attachments.length - 2}
                onClick={() => handleShowMoreClick()}
                showMore={showMore}
              /> */}
            </div>
          ) : confirmAttachment?.length === 1 ||
            draftMessageData?.attachments?.length === 1 ? (
            <div className=" flex flex-row flex-wrap bg-white p-3 rounded-b-[10px] border-t border-[#eceef1]">
              {confirmAttachment.map((file, i) => (
                <Attachment
                  file={JSON.parse(file?.body)}
                  //   getAttachment={getAttachmentLink}
                  fileId={file.sk1}
                  key={i}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )

  return (
    <>
      <div
        className={`message-container 
    ${className} 
    ${message.variant === 'CALL' && 'min-w-fit'}`}
      >
        <div className="px-2 pb-1 pt-2">
          <MessageBoxContainer ref={messageBoxRef}>
            <div className="flex absolute right-2.5 gap-2.5 -top-4 items-center h-9 w-full">
              <TooltipWithCenteredArrow
                variant="top"
                width="max-content"
                title={
                  message.type === 'phone'
                    ? phoneFormatter(user.channelAddress)
                    : user.channelAddress
                }
              >
                {/* get from selected channel?*/}
                <MessageSocialIcon variant={selectedChannel?.Provider} />
              </TooltipWithCenteredArrow>
            </div>

            {(message.variant === 'MSG' || message.variant === 'SMS') &&
              renderMessageContent}
          </MessageBoxContainer>
        </div>
        {sendMessageLoadingAnimation}
        {displayErrorMessage}
      </div>
    </>
  )
}
