import useAvatarComponent from '../../../../hooks/useAvatarComponent'
import { ThryvGuyIcon } from '../../../command-center-icon/icons'

export const UserAvatar = ({ isThryvGuy, imageUrl, userName }) => {
  const { RenderUserProfile } = useAvatarComponent({
    avatarSize: 'default',
    picture_url: imageUrl,
    contactName: userName,
  })

  return (
    <>
      {isThryvGuy ? (
        <ThryvGuyIcon width={'82'} height={'82'} />
      ) : (
        <RenderUserProfile />
      )}
    </>
  )
}
