// Remove the below line once it's confirmed that it's no longer in use
// import styleSearchTermInString from '../../../../../../../../utils/styleSearchTermInString'

// Components
import { CommandCenterAvatar } from '../../../../../../../common/command-center-avatar'
import Highlighter from 'react-highlight-words'

export const SearchContactCard = ({
  className,
  onClick,
  firstName,
  lastName,
  email,
  imageUrl,
  textToEmphasize,
}) => {
  const fullName = `${firstName}  ${lastName}`.trim()
  const hoverAnimClass = 'hover:bg-thryv-gray-light-200 duration-300 transition-all'

  return (
    <button
      type="submit"
      onClick={onClick}
      className={`flex items-center w-full max-w-full px-6 py-1 cursor-pointer ${hoverAnimClass} ${className} h-10`}
    >
      <CommandCenterAvatar
        imageUrl={imageUrl}
        firstName={firstName}
        lastName={lastName}
        avatarProps={{ size: 'small' }}
      />

      <div className="text-start ml-2 truncate flex flex-col">
        <Highlighter
          className="w-full truncate text-xs md:text-sm font-open-sans"
          highlightClassName="font-bold bg-transparent"
          searchWords={[textToEmphasize]}
          autoEscape={true}
          textToHighlight={fullName.length ? fullName : email}
        />

        {fullName && (
          <Highlighter
            className="w-full truncate text-xs md:text-sm text-[#A3A5A7] font-open-sans"
            highlightClassName="font-bold bg-transparent text-[#A3A5A7]"
            searchWords={[textToEmphasize]}
            autoEscape={true}
            textToHighlight={email}
          />
        )}
      </div>
    </button>
  )
}
