import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Header } from '@thryvlabs/maverick'
import RecentCall from './RecentCall'
import { FETCH_MESSAGES_BY_THREAD_ID } from '../../../../graphql'
import { LoadingDots } from '../../../common/loading-dots'

const CallsList = ({ show, recentCalls }) => {
  return (
    <ul
      className={`${
        show && 'opacity-100'
      } m-auto pointer-events-none opacity-0 transition-all duration-300 flex flex-col gap-4`}
    >
      {recentCalls.map((recentCall, i) => (
        <li key={i}>
          <RecentCall recentCallData={recentCall} />
        </li>
      ))}
    </ul>
  )
}

const RecentCalls = ({ show, threadpk = '', username = '', className }) => {
  const [recentCallsList, setRecentCallsList] = useState([])

  const { data: messages, loading } = useQuery(FETCH_MESSAGES_BY_THREAD_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      threadid: threadpk.split('#')?.at(0),
    },
  })

  const initRecentCallsList = () => {
    if (messages) {
      const recentCalls = messages?.queryMessagesByThread?.items?.filter(
        (x) =>
          (x?.chan_type === 'phone' && x.item_type === 'CALL') ||
          (x.chan_type === 'CC' && x.body.includes('started a video chat with')),
      )

      setRecentCallsList(recentCalls)
    } else {
      setRecentCallsList([])
    }
  }

  useEffect(() => {
    initRecentCallsList()
  }, [messages])

  return show ? (
    <div className={className}>
      <Header className="mb-3" fontWeight="semibold" variant="h8">
        Recent Calls
      </Header>

      <LoadingDots
        className={`${
          loading && 'opacity-100'
        } pointer-events-none opacity-0 transition-all duration-300 m-auto`}
      />

      <CallsList
        show={Boolean(recentCallsList.length) && !loading}
        recentCalls={recentCallsList}
      />

      <p
        className={`${
          Boolean(!recentCallsList.length) && !loading && 'opacity-100'
        } font-normal text-sm text-[#4D4D4D] font-['Open Sans'] opacity-0 transition-all duration-300 text-center`}
      >
        You have no recent calls with {username}.
      </p>
    </div>
  ) : null
}

export default RecentCalls
