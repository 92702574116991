export const scrollToSelectedThread = (threadId) => {
  const conversationRowElem = document.getElementById(`conversation-row-${threadId}`)

  if (conversationRowElem === undefined || conversationRowElem === null) return

  setTimeout(function () {
    conversationRowElem.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, 100)
}
