export function capitalizeFirstLetter(str) {
  if (!str) return '' // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function capitalizeWords(sentence) {
  return sentence
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ')
}
