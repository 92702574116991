import { Header, ParagraphText } from '@thryvlabs/maverick'

const IndustryContainer = ({ industry, clicked, setClicked }) => {
  const handleClick = ({ name, idx }) => {
    setClicked({ name, idx })
  }
  return (
    <div className="flex flex-col gap-[12px]">
      {industry?.map((name, idx) => {
        return (
          <div className="flex flex-col gap-[12px] cursor-pointer" key={idx}>
            <div
              data-testid={`industry-${name}`}
              onClick={() => handleClick({ name, idx })}
              className={`w-[280px] h-[36px] flex gap-[12px] items-center justify-between  px-4 bg-[#F8F9FB] ${
                clicked?.idx === idx
                  ? 'bg-[#FF500026] hover:bg-[#FF500026] border-[1.5px] border-[#FF5000] hover:border-[#FF5000]'
                  : 'bg-[#F8F9FB] hover:bg-[#ECEEF1]'
              }  z-10 rounded`}
            >
              <div className="flex gap-7 items-center">
                <ParagraphText
                  variant="reg"
                  color="#4D4D4D"
                  className={`  ${clicked?.idx === idx ? '!font-bold' : ''} `}
                >
                  {name}
                </ParagraphText>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const OnboardingIndustryPage = ({ clicked, setClicked }) => {
  localStorage.setItem('onboardingStep', 3)
  const industry = [
    'Animal Services',
    'Automotive Services',
    'Childcare Services',
    'Events',
    'Health Services',
    'Home Services',
    'Legal Services',
    'Real Estate',
    'Salons',
    'Wellness',
    'Other',
  ]
  return (
    <div className="flex flex-col items-center text-center md:text-start justify-center bg-white ">
      <Header fontWeight="semibold" variant="h1" className="mb-9">
        Last step, select the industry that best describes your business.
      </Header>

      <IndustryContainer
        industry={industry}
        clicked={clicked}
        setClicked={setClicked}
      />
    </div>
  )
}

export default OnboardingIndustryPage
