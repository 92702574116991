import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'

const CHANNEL_ACCESS_TYPES = {
  read: '1',
  'read/write': '2',
  'no access': '3',
}

const CHANNEL_STATUS = {
  'deleted channel': '0',
  'needs to reauth': '99',
}

const updateVisibilityState = (visibilitySetter, isVisible) => {
  if (isVisible === undefined) visibilitySetter(!isVisible)
  else visibilitySetter(isVisible)
}

const updateDisabledState = (disabledSetter, isDisabled) => {
  if (isDisabled === undefined) disabledSetter(!isDisabled)
  else disabledSetter(isDisabled)
}

export const useVideoAndPhoneButtonData = (contact) => {
  const { user } = useAuth0()

  const [phoneButtonDisabled, setPhoneButtonDisabled] = useState(true)
  const [phoneButtonVisible, setPhoneButtonVisible] = useState(false)

  const phoneButton = {
    isVisible: phoneButtonVisible,
    isDisabled: phoneButtonDisabled,
  }

  const [videoButtonDisabled, setVideoButtonDisabled] = useState(true)
  const [videoButtonVisible, setVideoButtonVisible] = useState(false)

  const videoButton = {
    isVisible: videoButtonVisible,
    isDisabled: videoButtonDisabled,
  }

  const toggleVideoButtonVisibility = (isVisible) => {
    updateVisibilityState(setVideoButtonVisible, isVisible)
  }

  const toggleVideoButtonDisabled = (isDisabled) => {
    updateVisibilityState(setVideoButtonDisabled, isDisabled)
  }

  const togglePhoneButtonVisibility = (isVisible) => {
    updateVisibilityState(setPhoneButtonVisible, isVisible)
  }

  const togglePhoneButtonDisabled = (isDisabled) => {
    updateDisabledState(setPhoneButtonDisabled, isDisabled)
  }

  const updateButtonVisibilityStates = () => {
    const hasVideoChannel = () => {
      return Boolean(
        channelsData?.queryConnectedChannelsByUser.filter((x) => {
          if (
            x.Status !== CHANNEL_STATUS['needs to reauth'] &&
            x.Status !== CHANNEL_STATUS['deleted channel'] &&
            x.Provider === 'video'
          ) {
            return true
          }
          return false
        }).length,
      )
    }

    const hasPhoneChannel = () => {
      return Boolean(
        channelsData?.queryConnectedChannelsByUser.filter((x) => {
          if (
            x.Status !== CHANNEL_STATUS['needs to reauth'] &&
            x.Status !== CHANNEL_STATUS['deleted channel'] &&
            x.Provider === 'phone'
          ) {
            return true
          }
          return false
        }).length,
      )
    }
    const videoChannelExists = hasVideoChannel()
    const phoneChannelExists = hasPhoneChannel()

    // Should always be visible regardless if the user has a video channel or not.
    if (videoChannelExists) toggleVideoButtonVisibility(true)
    else toggleVideoButtonVisibility(true)

    if (phoneChannelExists) togglePhoneButtonVisibility(true)
    else togglePhoneButtonVisibility(false)
  }

  const updateButtonDisabledStates = () => {
    const hasVideoChannelAccess = () => {
      return Boolean(
        channelsData?.queryConnectedChannelsByUser.filter((x) => {
          if (
            x.Status !== CHANNEL_STATUS['needs to reauth'] &&
            x.Status !== CHANNEL_STATUS['deleted channel'] &&
            x.AccessType === CHANNEL_ACCESS_TYPES['read/write'] &&
            x.Provider === 'video'
          ) {
            return true
          }
          return false
        }).length,
      )
    }

    const hasPhoneChannelAccess = () => {
      return Boolean(
        channelsData?.queryConnectedChannelsByUser.filter((x) => {
          if (
            x.Status !== CHANNEL_STATUS['needs to reauth'] &&
            x.Status !== CHANNEL_STATUS['deleted channel'] &&
            x.AccessType === CHANNEL_ACCESS_TYPES['read/write'] &&
            x.Provider === 'phone'
          ) {
            return true
          }
          return false
        }).length,
      )
    }

    const videoChannelAccessible = hasVideoChannelAccess()
    const phoneChannelAccessible = hasPhoneChannelAccess()

    if (videoChannelAccessible) toggleVideoButtonDisabled(false)
    else toggleVideoButtonDisabled(true)

    if (phoneChannelAccessible && contact.phone.length)
      togglePhoneButtonDisabled(false)
    else togglePhoneButtonDisabled(true)
  }

  const { data: channelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
    onCompleted: () => {
      updateButtonVisibilityStates()
      updateButtonDisabledStates()
    },
  })

  useEffect(() => {
    updateButtonVisibilityStates()
    updateButtonDisabledStates()
  }, [contact])

  return {
    videoButton,
    toggleVideoButtonVisibility,
    toggleVideoButtonDisabled,
    phoneButton,
    togglePhoneButtonVisibility,
    togglePhoneButtonDisabled,
  }
}
