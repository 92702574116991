import { useEffect, useState } from 'react'
import DefaultScreen from '../../recent-and-voicemail-components/default-screens/DefaultScreenMain'
import Recent from './Recent'
import { useSelector } from 'react-redux'
import { PaginatedList } from '@thryvlabs/maverick'

const LoadingRow = () => (
  <div className="w-full h-[74px] animate-pulse !bg-[#E2E8F0] rounded"></div>
)

const LoadingRows = ({ show, rows }) => {
  const allRows = []

  for (let i = 0; i < rows; i++) {
    allRows.push(i)
  }

  return (
    <div
      className={`${
        show ? 'opacity-100' : 'opacity-0'
      } transition-all duration-300 min-w-md:pt-[13px] min-h-full w-full absolute top-0 p-[20px] md:p-[0px] md:bg-white flex flex-col gap-[10px] pointer-events-none`}
    >
      {allRows.map((row, i) => (
        <LoadingRow key={i} />
      ))}
    </div>
  )
}

const getCallsWithContacts = (calls) => {
  const callsWithContacts = calls.map((call) => {
    const callWithContact = {
      ...call,
      contact: {
        ...call.contact,
        body: call.contact && call.contact.body ? JSON.parse(call.contact.body) : {},
      },
    }
    return callWithContact
  })

  return callsWithContacts
}

const Recents = ({
  setDisplayInfoCard,
  isMobile,
  contactInfoView,
  setContactInfoView,
  setShowMobileContact,
  loading,
}) => {
  const { selectedContact } = useSelector((state) => state.contacts)
  const tabSelected = 'recent'

  const [callsReceivedTab, setCallsReceivedTab] = useState('all')
  const calls = useSelector((state) => state.calls.calls)
  const [callsWithContacts, setCallsWithContacts] = useState([])

  const initCallsWithContacts = async () => {
    const newCallsWithContacts = await getCallsWithContacts(calls)

    setCallsWithContacts(newCallsWithContacts)
  }

  useEffect(() => {
    setCallsReceivedTab('all')
  }, [tabSelected, isMobile])

  useEffect(() => {
    initCallsWithContacts()
  }, [selectedContact, calls])

  return (
    <div className="relative">
      {Boolean(callsWithContacts.length) && (
        <div className="h-full min-w-md:pt-[13px] p-[20px] md:p-[0px] md:bg-white">
          <PaginatedList list={callsWithContacts}>
            <Recent
              contactInfoView={contactInfoView}
              setContactInfoView={setContactInfoView}
              setDisplayInfoCard={setDisplayInfoCard}
              isMobile={isMobile}
              callsReceivedTab={callsReceivedTab}
              setShowMobileContact={setShowMobileContact}
            />
          </PaginatedList>
        </div>
      )}

      <LoadingRows show={loading} rows={5} />

      {Boolean(!loading && !callsWithContacts.length) && (
        <DefaultScreen tabSelected="recent" isMobile={isMobile} />
      )}
    </div>
  )
}

export default Recents
